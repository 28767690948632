<template>
  <section class="main-bg-pattern">
    <ViewNav :sectionId="'timelineView'" :sectionPath="'../files/timeline.slm'" class="mb-5"/>
    <SectionHeader
      :sectionTitle="'Mon parcours'"
      :sectionText="timelineViewText"
      :sectionNumber="'02'"
    />
    <div class="container pb-5">
      <div class="row">
        <div class="col-1 custom-col-1 relative border-right-black">
          <div class="medium-square dark-bg pos-bm"></div>
        </div>
        <div class="col-md-10 custom-col-11  ps-4 pe-0 text-start section-content">
        
          <div class="timeline-wrapper relative">
            <div class="timeline-header mx-auto relative">
              <button class="btn"
                @click="toggleTimelineBtn(1)"
                :class="this.btn1IsActive == 1 ? 'timeline-btn1-active btn-custom-primary' : 'timeline-btn1-inactive btn-custom-secondary'"
              >
                Expériences
              </button>
              <button class="btn"
                @click="toggleTimelineBtn(0)"
                :class="this.btn1IsActive == 1 ? 'timeline-btn2-inactive btn-custom-secondary' : 'timeline-btn2-active btn-custom-primary'"
              >
                Formations
              </button>
            </div>
            <div class="timeline-screen screen-effect"></div>
            <div class="timeline-content relative" :class="this.btn1IsActive == 1 ? 'left-0' : 'right-0'">
              
                <TimeLine
                  v-if="this.btn1IsActive == 1"
                  class="timeline-element"
                  :data_list="this.workData"
                />
                <TimeLine
                  v-if="this.btn1IsActive == 0"
                  class="timeline-element"
                  :data_list="this.studentData"
                />
              </div>
            <div class="medium-square pos-br dark-bg"></div>
          </div>
        </div>
      </div>
    </div>
  </section>    
</template>

<script>
import ViewNav from '@/components/ViewNav.vue';
import TimeLine from '@/components/TimeLine.vue';
import SectionHeader from '@/components/ui/SectionHeader.vue';
export default {
  title: "Slimen Metatidj",
  name: 'SkillView',
  components: {
    ViewNav,
    SectionHeader,
    TimeLine
  },
  data(){
    return {
      timelineViewText: {
        sentence: [
          {
            class: 'section-header-first-letter',
            text: 'A'
          },
          {
            class: '',
            text: "près l'obtention d'un bac professionnel Création et Gestion d'une Exploitation Agricole dominante Élevage Ovin j'ai découvert le métier de développeur web. "
          },
          {
            class: '',
            text: "J'ai donc très tôt décidé de "
          },
          {
            class: 'bold',
            text: "me lancer dans une reconversion."
          },
          
        ],
        sentence2: [
          {
            class: '',
            text: " S'en est suivie"
          },
          {
            class: 'bold',
            text: ' une véritable passion'
          },
          {
            class: '',
            text: ", j'ai pu allier ma fascination pour la technologie et le plaisir de création."
          }

        ],
        sentence3: [
          {
            class: '',
            text: 'Grâce aux formations, à la confiance de mes employeurs et beaucoup de motivation je peux désormais '
          },
          {
            class: 'bold',
            text: ' pousser dans ce terreau fertile'
          },
          {
            class: '',
            text: " qu'est le numérique, où"
          },
          {
            class: 'bold',
            text: ' ligne après ligne nous ensemençons le champ des possibles.'
          },
        ]
      },
      btn1IsActive: 1,
      workData: [
        {
          time: '2023-?',
          title: '',
          subtitle: 'Développeur Full-Stack',
          text: 'Mon profil vous intéresse ?',
          skill: ['NodeJs', 'VueJs', 'Docker', 'Gitlab', 'Suite Adobe', 'Mcd'],
          type: 'input'
        },
        {
          time: '2022-2023',
          title: 'Smile',
          subtitle: 'Développeur Full-Stack',
          text: 'Développement front et back sur des applications en lignes, web services, solutions open-source...',
          skill: ['Linux','NestJs', 'Java', 'Php', 'Docker', 'AGILE'],
          type: 'input'
        },
        {
          time: '2020-2022',
          title: 'Collège de Paris',
          subtitle: 'Développeur Full-Stack',
          text: 'Développement en équipe d\'un CRM et d\'un Extranet pour la gestion des élèves.',
          skill: ['NodeJs', 'VueJs', 'Docker', 'Gitlab', 'Suite Adobe', 'Mcd'],
          type: 'display'
        },
        {
          time: '2020',
          title: 'CoAventure',
          subtitle: 'Développeur Wordpress',
          text: 'Création d\'un site de réservation d\'activités de groupes.',
          skill: ['Thème enfant', 'modifications et créations de plugins', 'modification du back-end', 'Mcd', 'Uml', 'maquettage', 'design'],
          type: 'display'
        },
        {
          time: '2019',
          title: 'Agence Akom',
          subtitle: 'Développeur Front-end',
          text: 'Création de sites vitrines et marchands, du brief client à la mise en production.',
          skill: ['Worpdress', 'Suite Adobe', 'Php', 'Javascript', 'Git', 'SEO', 'Communication'],
          type: 'display'
        }
      ],
      studentData: [
        {
          time: '2020-2021',
          title: 'Bac+5 Management de projets',
          subtitle: 'Keyce Academy',
          text: "Vision d'ensemble d'un projet informatique, organisation et gestion du déroulé.",
          skill: ['Cybersécurité', 'Organisation', 'Outil de management', 'Anglais', 'Communication'],
          type: 'display'
        },
        {
          time: '2020-2021',
          title: 'Bac+3 Système et réseau',
          subtitle: 'Keyce Academy',
          text: "Montée en compétence sur l'écosytème informatique d'une entreprise. Gestion de projet.",
          skill: ['Cybersécurité', 'ITIL', 'Ansible', 'Android', 'IOs', 'Base de données'],
          type: 'display'
        },
        {
          time: '2019',
          title: 'Bac+2 Développeur Web/Mobile Php',
          subtitle: 'ADRAR Formation',
          text: "Apprentissage de la partie back-end, conception et développement d'application.",
          skill: ['Php', 'Git', 'Javascript', 'Merise', 'MCD', 'UML', 'Symfony'],
          type: 'display'
        },
        {
          time: '2018',
          title: 'Bac+2 Développeur Front-End',
          subtitle: 'IMAGE Formation',
          text: "Prise en main de la partie front-end d'un site. Sensibilisation à l'accéssibilité, développement des compétences d'ui/ux, de design et veille technologique.",
          skill: ['Html', 'Css', 'Maquettage', 'Accessibilité', 'Design', 'SEO', 'responsive', 'Javascript', 'Suite Adobe'],
          type: 'display'
        }
      ],
    }
  },
  mounted(){

  },
  methods:{
    toggleTimelineBtn(value){
      this.btn1IsActive = value;
    }
  }
}
</script>

<style lang="scss" scoped>
// .timeline-element{
//   display: inline-block;
//   padding: 10px;
//   width: 50%;
// }

.timeline-primary-btn{
  clip-path: polygon(0 0%, 95% 0%, 100% 100%, 0 100%);
}

.timeline-btn1-active{
  bottom: -1px;
  clip-path: polygon(5% 0%, 95% 0%, 100% 100%, 0 100%);
  left: 30px;
  position: absolute;
  width: 250px;
}

.timeline-btn1-inactive{
  bottom: -1px;
  clip-path: polygon(5% 0%, 95% 0%, 100% 100%, 0 100%);
  left: 30px;
  position: absolute;
  width: 180px;
}

.timeline-btn2-active{
  bottom: -1px;
  clip-path: polygon(5% 0%, 95% 0%, 100% 100%, 0 100%);
  left: 210px;
  position: absolute;
  width: 250px;
}

.timeline-btn2-inactive{
  bottom: -1px;
  clip-path: polygon(5% 10%, 95% 10%, 100% 100%, 0% 100%);
  left: 280px;
  position: absolute;
  width: 180px;
}

.timeline-content{
  padding: 40px;
  border: 1px solid #212529;
  border-left: 4px solid #212529;
  background-color: #212529;
  height: 450px;
  margin-top: -1px;
  overflow: hidden;
  overflow-y: scroll;
}

.timeline-content::-webkit-scrollbar {
    width: 0.5em;
}

.timeline-content::-webkit-scrollbar-track {
    -webkit-box-shadow: unset;
  background-color: #212529;
}
 
.timeline-content::-webkit-scrollbar-thumb {
  background-color: #736e5b;
  border-radius: 50px;
  outline: 1px solid slategrey;
}

.timeline-header{
  button{
    height: 25px;
    line-height: 15px;
    padding: 5px;
    z-index: (140);
  }
}

.timeline-wrapper{
  margin: 0 auto;
  max-width: 750px;
  width: 100%;
}

.timeline-screen:before{
  height: 450px;
}

@media (max-width: 768px) { 
    .section-content{
      width: 90%;
      margin: 0 auto;
    }

    .timeline-content{
      padding: 20px;
    }
}
@media (max-width: 576px) { 
  .timeline-btn1-active{
  bottom: -1px;
  clip-path: polygon(5% 0%, 95% 0%, 100% 100%, 0 100%);
  left: 30px;
  position: absolute;
  width: 120px;
}

.timeline-btn1-inactive{
  bottom: -1px;
  clip-path: polygon(5% 0%, 95% 0%, 100% 100%, 0 100%);
  left: 30px;
  position: absolute;
  width: 100px;
}

.timeline-btn2-active{
  bottom: -1px;
  clip-path: polygon(5% 0%, 95% 0%, 100% 100%, 0 100%);
  left: 131px;
  position: absolute;
  width: 120px;
}

.timeline-btn2-inactive{
  bottom: -1px;
  clip-path: polygon(5% 10%, 95% 10%, 100% 100%, 0% 100%);
  left: 151px;
  position: absolute;
  width: 100px;
}
}
</style>