<template>

  <nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-dark screen-effect">
  <div class="container-fluid">
    <a class="navbar-brand mb-2" href="/home">
      <LogoCyb/>
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item link-nav pl-4"
          @mouseenter="addLinkNavStyle(0)"
          @mouseleave="deleteLinkNavStyle(0)"
        >
            <a href="/home#skillView">Compétences</a>
            <div class="data-link-nav"></div>
            <div class="data-link-nav-top"></div>
            <div class="data-link-nav-bottom"></div>
        </li>
        <div class="white-elm-wrapper">
          <div class="white-elm"></div>
        </div>
        <li class="nav-item link-nav pl-4"
          @mouseenter="addLinkNavStyle(1)"
          @mouseleave="deleteLinkNavStyle(1)"
        >
            <a href="/home#timelineView">Mon parcours</a>
            <div class="data-link-nav"></div>
            <div class="data-link-nav-top"></div>
            <div class="data-link-nav-bottom"></div>
        </li>
        <div class="white-elm-wrapper">
          <div class="white-elm"></div>
        </div>
        <li class="nav-item link-nav pl-4"
          @mouseenter="addLinkNavStyle(2)"
          @mouseleave="deleteLinkNavStyle(2)"
        >
            <a href="/home#portfolioView">Portfolio</a>
            <div class="data-link-nav"></div>
            <div class="data-link-nav-top"></div>
            <div class="data-link-nav-bottom"></div>
        </li>
        <div class="white-elm-wrapper">
          <div class="white-elm"></div>
        </div>
        <li class="nav-item link-nav pl-4"
          @mouseover="addLinkNavStyle(3)"
          @mouseleave="deleteLinkNavStyle(3)"
        >
            <a href="/home#">À propos</a>
            <div class="data-link-nav"></div>
            <div class="data-link-nav-top"></div>
            <div class="data-link-nav-bottom"></div>
        </li>
        <div class="white-elm-wrapper">
          <div class="white-elm"></div>
        </div>
        <li class="nav-item link-nav pl-4"
          @mouseenter="addLinkNavStyle(4)"
          @mouseleave="deleteLinkNavStyle(4)"
        >
            <a href="/home#">Contact</a>
            <div class="data-link-nav"></div>
            <div class="data-link-nav-top"></div>
            <div class="data-link-nav-bottom"></div>
        </li>
        <div class="white-elm-wrapper">
          <div class="white-elm"></div>
        </div>
      </ul>
    </div>
  </div>
</nav>


  <!-- <nav>
    
    <div class="row col-12 justify-content-between">
      <a class="col-1" href="/home#">
        <img src="../assets/logo.png" width="30" height="30" alt="">
      </a>
      <div class="row col-10 justify-content-end">
        <div class="link-nav">
          <a href="/home#">Compétences</a>
          <div class="data-link-nav"></div>
        </div>
        <div class="link-nav">
          <a href="/home#">Portfolio</a>
          <div class="data-link-nav"></div>
        </div>
        <div class="link-nav">
          <a href="/home#">À propos</a>
          <div class="data-link-nav"></div>
        </div>
        <div class="link-nav">
          <a href="/home#">Contact</a>
          <div class="data-link-nav"></div>
        </div>
      </div>
    </div>
  </nav> -->
</template>

<script>
import LogoCyb from '@/components/Logo.vue';

export default {
  name: 'MainNav',
  components: {
    LogoCyb
  },
  props: {
    msg: String
  },
  data() {
    return {
      whiteElmArray : [],
      linkElmArray : []
    }
  },
  mounted(){
    this.whiteElmArray = document.querySelectorAll('.white-elm');
    this.linkElmArray = document.querySelectorAll('.link-nav');
    this.linkElmRotateArray = document.querySelectorAll('.data-link-nav');
  },
  methods:{
    addLinkNavStyle(index){
      console.log('début fonction ajout');

      this.linkElmArray[index].style.border = '1px solid #f4eddb'
      this.linkElmRotateArray[index].style.borderTop = '1px solid #f4eddb'
      setTimeout(() => {
        console.log('dans le set add');
        console.log(this.whiteElmArray[index].style.width);
        this.whiteElmArray[index].style.width = '6px'
        console.log(this.whiteElmArray[index].style.width);
      }, 200);
    },
    deleteLinkNavStyle(index){
      setTimeout(() => {

      }, 200)
      console.log('début fonction retrait');

      this.whiteElmArray[index].style.width = '0px'
      setTimeout(() => {
        this.whiteElmArray[index].style.width = '0px'
        console.log('dans le set delete');
        this.linkElmArray[index].style.border = '1px solid #f4eddb00'
        this.linkElmRotateArray[index].style.borderTop = '1px solid #f4eddb00'  
      }, 200);
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  nav{
    padding: 10px 0;

    a{
      color: #f4eddb;
      display: in;
      font-family: Reactor;
      font-size: 10px;
      text-decoration: unset;
      vertical-align: middle;
    }

    .data-link-nav{
      background: #212529;
      border-top: 1px solid #f4eddb00;
      height: 20px;
      left: -11px;
      position: absolute;
      transform: rotate(135deg);
      top: -11px;
      transition: all 0.2s;
      width: 20px;
    }

    .data-link-nav-top{
      background: #212529;
      border-top: 1px solid #f4eddb00;
      height: 20px;
      left: 0px;
      position: absolute;
      top: -21px;
      transition: all 0.2s;
      width: 20px;
    }

    .data-link-nav-bottom{
      background: #212529;
      border-top: 1px solid #f4eddb00;
      height: 20px;
      left: -16px;
      position: absolute;
      top: 5px;
      transition: all 0.2s;
      width: 15px;
    }

    .link-nav{
      border: 1px solid #f4eddb00;
      display: inline-block;
      margin-right: 3px;
      padding-left: 14px;
      position: relative;
      text-align: left;
      transition: all 0.2s;
      width: 150px;
    }

    .logo{
      left: 0;
      top: 0;
      position: absolute;
    }

    .navbar-brand{
      margin-left: 20px;
      height: 65px;
      position: relative;
      width: 70px;
    }


    .navbar-nav{
      margin-left: auto;
    }

    .navbar-toggler {
      border: none;
    }

    .navbar-toggler:focus {
      box-shadow: none;
    }

    
  }

  .navbar-dark .navbar-toggler-icon {
    background-image: url('../assets/menu.png');
  }

  .white-elm{
    background-color: #f4eddb;
    bottom: -1px;
    content: " ";
    height: 26px;
    right: 0px;
    transition: all 0.2s;
    width: 0px;
  }

  .white-elm-wrapper{
    margin-right: 15px;
    width: 6px;
  }

  @media (max-width: 991px) { 
    .link-nav{
      margin-top: 20px;
    }

    nav{
      .navbar-brand{
        margin-left: 20px;
        height: 50px;
        position: relative;
        width: 40px;
      }
    }
    
  }

  

</style>
