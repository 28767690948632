<template>
  <section class="main-bg-pattern">
    <ViewNav class="mb-5"
      :sectionId="'skillView'"
      :sectionPath="'../files/skill.slm'"
    />
    <SectionHeader
      :sectionTitle="'Compétences'"
      :sectionText="skillViewText"
      :sectionNumber="'01'"
    />
    <div class="container pb-5">
      <div class="row">
        <div class="col-1 custom-col-1 relative border-right-black">
          <div class="medium-square dark-bg pos-bm"></div>
        </div>
        <div class="col-md-7 custom-col-11 mt-5  pe-3 text-start section-content">
          <CyberList
            v-if="$store.state.wWidth <= 768"
            class="mb-5"
            :titleList="'Les incontournables'"
            :items="basicSkill"
            :subtitle="'921DC-99'"
          />
          <CyberList
            class="mb-5"
            :titleList="'Je travaille avec au quotidien'"
            :items="dailySkill"
            :subtitle="'214BN-76'"
          />
          <CyberList
            class="mb-5"
            :titleList="'J\'utilise régulièrement'"
            :items="oftenSkill"
            :subtitle="'312CD-77'"
          />
          <CyberList
            class="mb-5"
            :titleList="'J\'aime particulièrement'"
            :items="passionSkill"
            :subtitle="'677FR-78'"
          />
        </div>
        <div class="col text-start p-0 section-content">
          <CyberListVertical
            v-if="$store.state.wWidth > 768"
            class="col-12 mt-5 col-md-8"
            :titleList="'Les incontournables'"
            :items="basicSkill"
            :alignItem="'text-center'"
            :col-width="'col-12'"
            :subtitle="'546SU-79'"
          />
        </div>
      </div>
    </div>
  </section>    
</template>

<script>
import ViewNav from '@/components/ViewNav.vue';
import SectionHeader from '@/components/ui/SectionHeader.vue';
import CyberList from '@/components/ui/CyberList.vue';
import CyberListVertical from '@/components/ui/CyberListVertical.vue';
export default {
  title: "Slimen Metatidj",
  name: 'SkillView',
  components: {
    ViewNav,
    SectionHeader,
    CyberList,
    CyberListVertical
  },
  data(){
    return {
      skillViewText: {
        sentence: 
          [
            {
              class: 'section-header-first-letter',
              text: 'O'
            },
            {
              class: '',
              text: 'utre l\'inséparable trio que forme '
            },
            {
              class: 'bold',
              text: ' Html, Css, Javascript'
            },
            {
              class: '',
              text: ' je travaille quotidiennement avec un'
            },
            {
              class: 'bold',
              text: ' stack full Js avec Node et Vue'
            },
            {
              class: '',
              text: '.'
            },
            {
              class: 'd-block',
              text: 'Je maîtrise aussi le Php au depuis ma formation et le pratique régulièrement au sein de mes sides project.'
            }
          ]
      }
        
      ,
      basicSkill: {
        boostrap: {
          name: 'bootstrap',
          displayName: 'Bootstrap',
          alt: 'Bootstrap'
        },
        html: {
          name: 'html',
          displayName: 'Html 5',
          alt: 'Html 5'
        },
        css: {
          name: 'css3',
          displayName: 'Css 3',
          alt: 'Css 3'
        },
        javascript: {
          name: 'javascript',
          displayName: 'Javascript',
          alt: 'Javascript'
        }
      },
      dailySkill:{
        nodejs: {
          name: 'nodejs',
          displayName: 'Node js',
          alt: 'Node Js'
        },
        git: {
          name: 'git',
          displayName: 'Git',
          alt: 'Git, Github & Gitlab'
        },
        vue: {
          name: 'vue',
          displayName: 'Vue Js',
          alt: 'Vue js'
        },
        docker: {
          name: 'docker',
          displayName: 'Docker',
          alt: 'Docker'
        },
        sql: {
          name: 'sql',
          displayName: 'MySql',
          alt: 'MySql'
        },
        tailwind: {
          name: 'tailwind',
          displayName: 'Tailwind',
          alt: 'Tailwind'
        },
        npm: {
          name: 'npm',
          displayName: 'Npm',
          alt: 'Npm'
        },
      },
      oftenSkill: {
        php: {
          name: 'php',
          displayName: 'Php',
          alt: 'Php'
        },
        symfony: {
          name: 'symfony',
          displayName: 'Symfony',
          alt: 'Symfony'
        },
        laravel: {
          name: 'laravel',
          displayName: 'Laravel',
          alt: 'Laravel'
        },
        wordpress: {
          name: 'wordpress',
          displayName: 'Wordpress',
          alt: 'Wordpress'
        },
        prestashop: {
          name: 'prestashop',
          displayName: 'Prestashop',
          alt: 'Prestashop'
        },
      },
      passionSkill: {
        blender: {
          name: 'blender',
          displayName: 'Blender',
          alt: 'Blender'
        },
        photoshop: {
          name: 'photoshop',
          displayName: 'Photoshop',
          alt: 'Photoshop'
        },
        illustrator: {
          name: 'illustrator',
          displayName: 'Illustrator',
          alt: 'Illustrator'
        },
        xd: {
          name: 'xd',
          displayName: 'Xd',
          alt: 'Xd'
        },
      },
    }
  },
  mounted(){

  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>
// #skillView::after{
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   height: 800px;
//   width: 100%;
//   z-index: 0!important;
//   opacity: 0.10;
//   background-size: 8px 8px ;
//   background-image: url('../assets/cross-bg4.png');
// }

// .custom-col-1{
//   width: ;
// }

.custom-col-11{

}

@media (max-width: 768px) { 
    // .custom-col-11{
    //   width: 91%!important;
    //   padding: 0!important;
    // }
    // .custom-col-1{
    //   width: 45px!important;
    // }
}
@media (max-width: 551px) { 
    // .custom-col-11{
    //   width: 82%!important;
    // }
    // .custom-col-1{
    //   width: 39px!important;
    // }
}
</style>