<template>
    <div :class="iconWrapperClass"
    >
      <div
        class="cyber-list-item"
        @mouseover="defineDisplayColor()"
        @mouseout="defineDisplayUncolor()"
      >
        <div class="small-square dark-bg pos-tr"></div>
        
        <img 
          v-if="displayIconColor == false"
          class="h-10 icon" :src="createIconPath()" :alt="createIconAlt()"
        />
        <img 
          v-if="displayIconColor == true"
          class="h-10 icon" :src="createIconPath('color-')" :alt="createIconAlt()" 
        />
      </div>
      <div>
        <p class="m-0">{{ icon.displayName }}</p>
      </div>
    </div>
</template>

<style scoped>
</style>

<script>

export default {
  components: {

  },
  props: {
    iconWrapperClass: {
      type: String,
      required: false
    },
    icon: {
      type : Object,
      required: true
    },
    height: {
      type : Number,
      required: false
    }
  },
  data() {
    return {
      displayIcon: false,
      displayIconColor: false
    };
  },
  mounted(){
    this.createIconPath();
  },
  methods: {
    createIconPath(path) {
      if(path != undefined) return 'image/icon/skills/' + path + this.icon.name + '.png';
      else return 'image/icon/skills/' + this.icon.name + '.png';
    },
    defineDisplayColor(){
      this.displayIconColor = true;
    },
    defineDisplayUncolor(){
      this.displayIconColor = false;
    },
    createIconAlt() {
      return 'Logo ' + this.icon.alt
    }
  },
};
</script>
<style lang="scss">
.cyber-list-item{
  display: inline-block;
  height: 50px;
  position: relative;
  max-width: 50px;
  width: 50px;
  padding: 5px;

  .small-square{
    height: 0;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 0;
    transition: all 0.25s;

  }
}

.cyber-list-item:hover > .small-square{

  height: 10px;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  transition: all 0.25s;
  width: 10px;
}

.cyber-list-item::after{
  content: "";
  border: 1px solid rgba(0, 0, 0, 0);
  display: inline-block;
  height: 0px;
  left: 50%;
  position: absolute;
  top: 50%;
  transition: all 0.25s;
  width: 0px;
}
.cyber-list-item:hover::after{
  content: "";
  border: 1px solid #212529;
  display: inline-block;
  height: 100%;
  max-width: 50px;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.25s;
  width: 100%;
}

@media (max-width: 992px) { 
   .icon{
    margin-top: 6px;

     width: 30px;
   }
   .cyber-list-item {
      height: 40px;
      width: 40px;
      padding: 0px;
  }
}

@media (max-width: 576px) { 
   .icon{
    margin-top: 6px;
     width: 30px;
   }
   .cyber-list-item {
      height: 40px;
      width: 40px;
      padding: 0px;
  }
}

@media (max-width: 572px) { 
   .icon{
     width: 25px;
   }
}
</style>