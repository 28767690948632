<template>
<div class="wrapper">
  <a :href="this.link" class="btn-wrapper">
    <div class="custom-btn-light">
      <div 
        @mouseover="glitch()"
        @mouseleave="unglitch()"
        class="btn-text"
      >
      {{ buttonText }}
      </div>
    </div>
  </a>
  <a :href="this.link" class="btn-wrapper-glitched">
    <div class="custom-btn-light">
      <div 

        class="btn-text"
      >
      {{ buttonText }}
      </div>
    </div>
  </a>

</div>
</template>

<script>
export default {
  name: 'CyberButton',
  props: {
    link: {
      type: String,
      default: '#'
    },
    text: {
      type: String,
      default: 'Disc!0<er'
    }
  },
  data(){
    return {
      buttonText: '',
      randomChar: [
        "X",
        "<",
        ">",
        "?",
        "ù",
        "$",
        "%",
        "X",
        "@",
        "4",
        "5",
        "8",
        "=",
        "&",
        "\\",
        "/",
        "0",
        "1",
        "2",
        "3",
        "6",
        "7",
        "9",
        "G",
        "Y",
        "+",
        "*",
        "*",
        "%",
        "%",
        "%",
        "%",
      ],
    }
  },
  mounted(){
    this.buttonText = this.text
  },
  methods: {
    glitch(){
      let newString =  this.buttonText.split('');
      if(this.getRandomArbitrary(0, 100) > 90){
        this.buttonText = 'Error!';
      }
      else if(this.getRandomArbitrary(0, 100) < 10){
        this.buttonText = 'Alert!';
      }else{
         for(let i = 0; i < newString.length; i++){
        let random = this.getRandomArbitrary(0, 100);
        
        if(newString[i] != " " && (random > 70 && random < 90)){
          newString[i] = this.randomChar[Math.round(this.getRandomArbitrary(0, this.randomChar.length))]
        }
      }
      this.buttonText = newString.join('');
      }
     
    },
    unglitch(){
      this.buttonText = this.text
    },
    getRandomArbitrary(min, max) {
      return Math.random() * (max - min) + min;
    }
  }
}
</script>

<style scoped lang="scss">

  .wrap-test{
    width: 1800px;
  }

  .wrapper{
    height: 40px;
    padding: 0;
    position: relative;
    width: 200px;

  }

  .btn-text{
    font-family: maven;
    font-size: 14px;
    font-weight: 1000;
    letter-spacing: 3px;
  }

  .btn-wrapper{
    text-decoration: none;
  }

  .custom-btn-light{
    padding: 0;
    position: relative;
    div{
      background-color: #f4eddb;
      clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%, 0% 45%);
      color: black;
      line-height: 40px;
      text-align: center;
    }
  }

   .wrapper:hover > .btn-wrapper-glitched{
     animation: btn-glitch 0.9s infinite;
     display: inherit;
   }

  .cl1{
    background-color: #e77706;
    clip-path: polygon(10% 0, 100% 0, 100% 30%, 0% 30%, 0% 30%);
    height: 40px;
    margin: 10px 0;
    width: 200px;
  }
  .cl2{
    background-color: #e77706;
    clip-path: polygon(0 50%, 100% 50%, 100% 9%, 0 9%);
    height: 40px;
    margin: 10px 0;
    width: 200px;
  }
  .cl3{
background-color: #e77706;
    clip-path: polygon(0 60%, 100% 60%, 100% 50%, 0 50%);
    height: 40px;
    margin: 10px 0;
    width: 200px;
  }
  .cl4{
background-color: #e77706;
    clip-path: polygon(0 80%, 100% 80%, 100% 60%, 0 60%);
    height: 40px;
    margin: 10px 0;
    width: 200px;
  }
  .cl5{
background-color: #e77706;
    clip-path: polygon(0 100%, 100% 100%, 100% 70%, 0 70%);
    height: 40px;
    margin: 10px 0;
    width: 200px;
  }


   .btn-wrapper-glitched{
    background-color: transparent;
    clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%, 0% 45%);
    color: black;
    display: none;
    height: 40px;
    line-height: 40px;
    padding: 0;
    position: absolute;
    right: 0px;
    text-decoration: none;
    top: 0px;
    text-align: center;
    --shimmy-distance: 1;
    --clip-one: polygon(10% 0, 100% 0, 100% 30%, 0% 30%, 0% 30%);
    --clip-two: polygon(0 50%, 100% 50%, 100% 9%, 0 9%);
    --clip-three: polygon(0 60%, 100% 60%, 100% 50%, 0 50%);
    --clip-four: polygon(0 80%, 100% 80%, 100% 60%, 0 60%);
    --clip-five: polygon(0 100%, 100% 100%, 100% 70%, 0 70%);
    width: 200px;
    z-index: 0;
  }




  .wrapper::before{
    content: " ";
    bottom: 0px;
    border-bottom: 1px solid #f4eddb;
    border-right: 1px solid #f4eddb;
    height: 0px;
    left: 0;
    position: absolute;
    transition: left bottom 0.2s;
    width: 200px;
  }

  .wrapper:hover::before{
    animation: glitchDisplay 1.5s infinite;
    bottom: -10px;
    border-bottom: 1px solid #f4eddb;
    border-right: 1px solid #f4eddb;
    content: " ";
    height: 25px;
    left: 0;
    position: absolute;
    transition: left bottom 0.2s;
    width: 210px;
  }

  @keyframes btn-glitch {
  0%, 18% {
    clip-path: var(--clip-one);
    transform: translate(calc(var(--shimmy-distance) * -3%), 0);
  }
  18%, 34% {
    clip-path: var(--clip-two);
    transform: translate(calc(var(--shimmy-distance) * 3%), 0);
  }
  34%, 48% {
    clip-path: var(--clip-three);
    transform: translate(calc(var(--shimmy-distance) * -3%), 0);
  }

  48%, 60% {
    clip-path: var(--clip-four);
    transform: translate(calc(var(--shimmy-distance) * 3%), 0);
  }
  70%, 80% {
    clip-path: var(--clip-five);
    transform: translate(calc(var(--shimmy-distance) * -3%), 0);
  }
  86%, 92% {
    clip-path: var(--clip-three);
    transform: translate(calc(var(--shimmy-distance) * 3%), 0);
  }
  92% {
    clip-path: var(--clip-two);
    transform: translate(calc(var(--shimmy-distance) * -3%), 0);
  }
  // 31%, 40%, 100% {
  //   clip-path: var(--clip-four);
  // }
}

  @keyframes glitchDisplay {
    0%{
      opacity: 0;
    }
    20%{
      opacity: 1;
    }
    21%{
      opacity: 0;
    }
    50%{
      opacity: 0;
    }
    51%{
      opacity: 1;
    }
    93%{
      opacity: 0;
    }
    94%{
      opacity: 1;
    }
    100%{
      opacity: 1;
    }
  }

</style>
