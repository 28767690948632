<template>
  <div class="viewNav">
    <span :id="sectionId"></span>
    <div class="viewNavDisplay">
        <p>{{ sectionPath }}</p>
        <div class="rotate-square-small-left"></div>
        <div class="rotate-square-small-right"></div>
    </div>
    
  </div>    
</template>
<script>

export default {
  props:{
    sectionId: {
      type: String,
      default: ''
    },
    sectionPath: {
      type: String,
      default: 'undefined location'
    },
  },
  data(){
    return {
      
    }
  },
  mounted(){

  },
  methods:{
    
  }
}
</script>

<style lang="scss" scoped>
.rotate-square-small-left{
  background-color: #212529;
  height: 20px;
  left: -21px;
  transform: rotate(45deg);
  top: -5px;
  position: absolute;
  width: 33px;
}

.viewNav{
    background-color: #212529;
    position: relative;
    height: 14px;
    span{
      position: absolute;
      top: -92px;
    }
}

.viewNavDisplay{
    background-color: #212529;
    position: absolute;
    right: 0px;
    padding: 0 10px;
    width: 320px;
    bottom: -24px;
    height: 24px;
    p{
      font-size: 10px;
      line-height: 17px;
      margin-bottom: 5px;
      text-align: left;
      font-family: 'Maven';
      color: #F4EDDB;
    }
}
</style>