<template>
  <div class="timeline-element-popup">
    <div class="white-dot pos-ct"></div>
    <div class="timeline-item-header" :class="this.sidePopup">
      {{ data_item.title }}
      <p class="timeline-time font-s-8 reactor" :class="this.sidePopup == 'timeline-left-item' ? 'timeline-time-right' : 'timeline-time-left'">{{ data_item.time }}</p>
      <div class="timeline-item-content">
        <h6 class="font-s-8 reactor mt-1 mb-2">{{ data_item.subtitle }}</h6>
        <p class="font-s-12 mb-0">{{ data_item.text }}</p>
        <hr class="my-2">
        <p class="font-s-12 mb-1">
          {{ data_item.skill }}
        </p>
      </div>
      <div class="timeline-item-content-glitch">
        <h6 class="font-s-8 reactor mt-1 mb-2">{{ data_item.subtitle }}</h6>
        <p class="font-s-12 mb-0">{{ data_item.text }}</p>
        <hr class="my-2">
        <p class="font-s-12 mb-1">
          {{ data_item.skill }}
        </p>
      </div>
    </div>
    
    <div class="arrow-top bg-secondary">

    </div>
  </div>
</template>

<script>

export default {
  name: 'TimelineItem',
  props: {
    data_item: {
      type: Object,
      required: true
    },
    sidePopup: {
      type: String,
      default: "none"
    },
  },
}
</script>

<style lang="scss">
.arrow-top{
  width: 25px;
  height: 12px;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0%);
  top: 5px;
}

.timeline-item-content{
  background-color: #f4eddb;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  left: 0;
  color: #212529;
  -webkit-text-stroke: 0.5px #212529;
  display: none;
  top: 30px;
  padding: 8px 6px;
  position: absolute;
  width: 100%;
}
.timeline-item-content-glitch{
  animation: glitchTimeline 0.4s 1;
  background-color: #f4eddb;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #212529;
  -webkit-text-stroke: 0.5px #212529;
  display: none;
  left: 0;
  top: 30px;
  padding: 8px 6px;
  position: absolute;
  width: 100%;

  --shimmy-distance: 1;
  --clip-one: polygon(0 0%, 100% 0%, 100% 10%, 0 10%);
  --clip-two: polygon(0 5%, 100% 5%, 100% 24%, 0 24%);
  --clip-three: polygon(0 24%, 100% 24%, 100% 45%, 0 45%);
  --clip-four: polygon(0 45%, 50% 45%, 50% 55%, 0 55%);
  --clip-five: polygon(0 55%, 100% 55%, 100% 90%, 0 90%);
  // --clip-six: polygon(0 70%, 100% 70%, 100% 90%, 0 90%);
  --clip-six: polygon(0 90%, 100% 90%, 100% 100%, 0 100%);
}

.timeline-left-item{
  position: absolute;
  left: -30px;
  min-width: 270px;
}

.timeline-time{
  position: absolute;
  top: -13px;
  color: #f4eddb!important;
  -webkit-text-stroke: 0 #212529;
}

.timeline-time-right{
  right: 5px;
}

.timeline-right-item{
  position: absolute;
  right: -30px;
  min-width: 270px;
}

.timeline-item-header{
  background-color: #736e5b;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  color: #f4eddb;
  letter-spacing: 2px;
  font-family: 'Reactor';
  font-size: 7px;
  margin-top: 16px;
  padding: 8px 6px;
  -webkit-text-stroke: 0.5px #f4eddb;
}

.timeline-item-header:hover > .timeline-item-content, .timeline-item-header:hover > .timeline-item-content-glitch{
  display: inherit;
}

@media (max-width: 768px) {
  .timeline-left-item{
    position: absolute;
    left: 50%;
    min-width: 270px;
    transform: translate(-50%, 0);
  }

  .timeline-right-item{
    position: absolute;
    right: 50%;
    min-width: 270px;
    transform: translate(50%, 0);
  }

}

@keyframes glitchTimeline {
    0% {
      clip-path: var(--clip-one);
    }
    0%, 25% {
      clip-path: var(--clip-one);
      transform: translate(calc(var(--shimmy-distance) * 2%), 0);
    }
    25%, 45% {
      clip-path: var(--clip-two);
      transform: translate(calc(var(--shimmy-distance) * -2%), 0);
    }
    45%, 55% {
      clip-path: var(--clip-three);
      transform: translate(calc(var(--shimmy-distance) * 1%), 0);
    }
    55%, 75% {
      clip-path: var(--clip-four);
      transform: translate(calc(var(--shimmy-distance) * -2%), 0);
    }
    78%{
      clip-path: var(--clip-three);
      transform: translate(calc(var(--shimmy-distance) * -0.1%), 0);
    }
    75%, 90% {
      clip-path: var(--clip-five);
      transform: translate(calc(var(--shimmy-distance) * 2%), 0);
    }
    90%, 100% {
      clip-path: var(--clip-six);
      transform: translate(calc(var(--shimmy-distance) * 2%), 0);
    }
  }
</style>