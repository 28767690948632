<template>
  <div class="cyber-list-vartical">
    <h4 class="mb-2">{{ titleList }}</h4>
    <div :class="colWidth">
      <div class="col-md-12 col-4 mx-auto row m-0 border-left-black">
        <div class="p-0 m-0 vertical-warpper border-top-black border-bottom-black relative text-center">
          <div class="small-square dark-bg pos-br"></div>

          <div class="small-triangle-dark-tl"></div>
          <div class="p-3">
            <div
              v-for="(item, index) in this.items" :key="index" :index="index"
              class="cyber-list-vartical-warpper mx-1 relative"
            >
              <IconItem
                v-if="iconList == true"
                class="iconWrapper"
                :class="index == 'boostrap' ? mt0 : mt2"
                :icon="item"
              />
              <p v-else>
                {{ item }}
              </p>
            </div>
          </div>
        </div>
        <div class="border-left-black relative cyber-list-vertical-side-border p-0">
          <div class="list-vertical-subtitle relative dark-bg">
            <div class="triangle-tl triangle-pos-bl list-vertical-subtitle-triangle dark-bg"></div>
            <div class="triangle-bl triangle-pos-tr list-vertical-subtitle-triangle dark-bg"></div>
            <small class="rotate-title">{{ subtitle }}</small>
          </div>
        <div class="small-square dark-bg pos-br"></div>
          <div class="small-square dark-bg pos-b"></div>
        </div>
      </div>
    </div>
  </div>    
</template>

<script>
import IconItem from '@/components/ui/icon/IconItem.vue'

export default {
  components: {
    IconItem
  },
  name: 'SectionHeader',
  props:{
    alignItem: {
      type: String,
      default: 'text-left'
    },
    colWidth: {
      type: String,
      default: 'col-3'
    },
    iconList: {
      type: Boolean,
      default: true
    },
    subtitle: {
      type: String,
      default: 'Place subtitle here'
    },
    items: {
      type: Object,
      default() {
        return {
          Klingon: {
            name: 'Klingon',
            displayName: 'Klingon',
            alt: 'Klingon'
          }
        }
      }
    },
    titleList: {
      type: String,
      default: 'Place title here'
    },
  },
  cyberListTitle: {
    type: String,
    default: 'Magnam id'
  },
  data(){
    return {
      mt0: 'mt-0',
      mt2: 'mt-2',
    }
  },
  mounted(){

  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>
.cyber-list-vertical-warpper{
  max-width: 100%;
  display: inline-block;
}

.cyber-list-vertical-side-border{
  width: 1px;
  height: 300px;
}

.vertical-warpper{
  width: fit-content;
}

.list-vertical-subtitle{
  color: #f4eddb;
  font-family: "Reactor";
  font-size: 7px;
  letter-spacing: 1px;
  height: 200px;
  left: 0;
  top: 20px;
  line-height: 20px;
  padding: 20px 0;
  position: absolute;
  width: 20px;
}

.list-vertical-subtitle-triangle{
  height: 20px;
  width: 20px;
}

.custom-col-11{

}

.rotate-title {
  font-family: Reactor;
  font-size: 7px;
  width: 200px;
  position: absolute;
  transform: rotate(90deg);
  right: -89px;
  top: 92px;
  z-index: 100;
}

@media (max-width: 992px) { 
  .iconWrapper{
    padding: 0 3px;
    margin-top: 8px;
  }
}
</style>