<template>
    <div id="footer" class="relative">
      <div class="row footer-top-row w-100 m-0">
        <div class="col-4 bg-dark relative screen-effect">
          <div class="list-subtitle-triangle dark-bg triangle-bl triangle-pos-test"></div>
        </div>
        <div class="col-4 main-bg-pattern"></div>
        <div class="col-4 bg-dark relative screen-effect">
          <div class="list-subtitle-triangle dark-bg triangle-br triangle-pos-tl"></div>
        </div>
      </div>
      <div class="bg-dark pt-5 relative pb-2">
        <div class="container screen-effect">
          <div class="row justify-content-center">
            <ul class="col-md-3 text-md-start text-sm-center">
              <a class="footer-brand" href="#">
                <LogoCyb class="text-sm-center"/>
              </a>
              <li>
                <p>J'étudie toutes propositons, <br>prenez contact !</p>
              </li>
              <li>
                <!-- <img src="" alt=""> -->
              </li>
            </ul>
            <ul class="col-md-3 text-md-start text-sm-center">
              <li><router-link class="link-white" to="/">Accueil</router-link></li>
              <li><router-link class="link-white" to="/">A propos</router-link></li>
              <li><router-link class="link-white" to="/">Porfolio</router-link></li>
              <li><router-link class="link-white" to="/">Contact</router-link></li>
            </ul>
            <ul class="col-md-3 text-md-start text-sm-center">
              <li><router-link class="link-white" to="/">Accueil</router-link></li>
              <li><router-link class="link-white" to="/">A propos</router-link></li>
              <li><router-link class="link-white" to="/">Porfolio</router-link></li>
              <li><router-link class="link-white" to="/">Contact</router-link></li>
            </ul>
          </div>
          <p class="m-0">Tous droits réservés - Slimen Metatidj 2023</p>
          <p class="m-0">À base de Vue.Js </p>
        </div>
      </div>
    </div>
</template>
<script>
  import LogoCyb from '@/components/Logo.vue';
  export default {
    name: 'FooterItem',
    components: {
      LogoCyb
    },
  }
</script>
<style lang="scss">
#footer{
  color: #f4eddb;
  li{
    //background-color: #f4eddb;
    list-style: none;
  }

  a{
    color: #f4eddb;
    text-decoration: none;
    font-family: 'Maven';
  }

  .footer-brand{
    width: 70px;
    margin: 0 auto;
    .logo-wrapper{
      width: 70px;
      height: auto;
    }
    img{
      height: auto;
    }
  }

  .footer-top-row{
    height: 20px;
  }
}

@media (max-width: 769px) {
  #footer .footer-brand .logo-wrapper{
    margin: 0 auto;
  }
}
</style>