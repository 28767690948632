<template>
  <section class="home">
    <div class="container h-50">
      <div class="col-6 row border">
        <div class="col-1 border">lorem</div>
        <div class="col-9 border">lorem</div>
      </div>
        <div class="rotate-title-frame">
          <div class="rotate-title">
            <small>V01.00 ACCESS AUTHORIZED</small>
            <p>SM-CV03</p>
          </div>
        </div>
        <div class="col-6 h-100 wrap-content">
          <div class="white-small-square-bl"></div>
          <div class="white-triangle-tl"></div>
          <h1 class="text-left">À la recherche d’un dÉveloppeur ?</h1>
          <div class="col-6 white-line-separator">
            <div class="white-small-square"></div>
          </div>
          <p class="mt-3">Adepte de site attrayant je m’épanouis dans la création digital. Au travers d’illustrations, d’algorithmes et d’interfaces je donne vie à mes idées.</p>
        </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
  }
}
</script>

<style lang="scss">
.home {
  background-image: url('../assets/image/main-bg.jpg');
  background-size: cover;
  background-position: center;
  color: #f4eddb;
  height: 100vh;
  padding-top: 15%;
  text-align: left;

  .container{
    position: relative;
  }

  h1{
    border-top: 1px solid #f4eddb;
    border-right: 1px solid #f4eddb;
    font-family: Reactor;
    font-size: 32px;
    line-height: 54px;
    letter-spacing: 2px;
    margin: 0;
    padding: 40px 20px 15px 0;
  }

  .wrap-content{
    padding-left: 40px;

    .white-triangle-tl{
      border-top: 2px solid #f4eddb;
      height: 80px;
      left: -40px;
      position: absolute;
      transform: rotate(135deg);
      top: -39px;
      transition: all 0.2s;
      width: 80px;
    }
    
    .white-small-square-bl{
      height: 10px;
      width: 10px;
      background-color: #f4eddb;
      border: none;
      bottom: 0;
      position: absolute;
      transform: translate(-50%, -0%);
      left: 0;
    }
  }

  .wrap-content::before{
    content: " ";
    border-left: 1px solid #f4eddb;
    position: absolute;
    height: 381px;
    width: 50px;
    left: 0;
    bottom: 0;
  }

  .white-line-separator{
    background-color: #f4eddb;
    color: #f4eddb;
    height: 1px;
    margin: 0 0 0 auto;
    opacity: 1;
    position: relative;
    width: 50%;

    .white-small-square{
      height: 10px;
      width: 10px;
      background-color: #f4eddb;
      border: none;
      position: absolute;
      transform: translate(-50%, -50%);
    }
  }

  .rotate-title-frame{
    border: 1px solid purple;
    top: 0;
    left: -141px;
    position: absolute;
    .rotate-title{
      writing-mode: vertical-rl;
      text-orientation: mixed;

      p{
        font-family: Reactor;
        font-size: 78px;
        line-height: 1;
        margin: 0;
      }

      small{

      }
    }
  }


}
</style>
