<template>
  <div class="cyber-list ps-3">
    <h4 class="mb-2">{{ titleList }}</h4>
    <div class="col-md-8 relative border-top-black">
      <div class="small-square dark-bg pos-tr"></div>
      <div class="small-triangle-dark-tl"></div>
    </div>
    <div class="col-12 row m-0 border-left-black">
      <div class="p-3" :class="alignItem">
        <div
          v-for="(item, index) in this.items" :key="index" :index="index"
          class="row cyber-list-warpper mx-1 relative"
        >
          
            <IconItem
              v-if="iconList == true"
              class="text-center iconWrapper"
              :icon="item"
            />
            <p v-else>
              {{ item }}
            </p>
      </div>
      </div>
    </div>
    <div class="col-md-6 col-10 relative border-bottom-black">
      
      <div class="list-subtitle relative dark-bg">
        <div class="triangle-tr triangle-pos-tl list-subtitle-triangle dark-bg"></div>
        <div class="triangle-tl triangle-pos-br list-subtitle-triangle dark-bg"></div>
        {{ subtitle }}
      </div>
      <div class="small-square dark-bg pos-br"></div>
    </div>
  </div>    
</template>

<script>
import IconItem from '@/components/ui/icon/IconItem.vue'

export default {
  components: {
    IconItem
  },
  name: 'SectionHeader',
  props:{
    alignItem: {
      type: String,
      default: 'text-left'
    },
    iconList: {
      type: Boolean,
      default: true
    },
    items: {
      type: Object,
      default() {
        return {
          Klingon: {
            name: 'Klingon',
            displayName: 'Klingon',
            alt: 'Klingon'
          }
        }
      }
    },
    subtitle: {
      type: String,
      default: 'Place subtitle here'
    },
    titleList: {
      type: String,
      default: 'Place title here'
    },
  },
  cyberListTitle: {
    type: String,
    default: 'Magnam id'
  },
  data(){
    return {
      
    }
  },
  mounted(){

  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>
.cyber-list-warpper{
  width: fit-content;
  display: inline-block;
}

.list-subtitle{
  color: #f4eddb;
  font-family: 'Reactor';
  font-size: 7px;
  letter-spacing: 1px;
  height: 20px;
  left: 19px;
  line-height: 20px;
  padding: 0 0 0 3px;
  position: absolute;
  width: 80%;
}

.list-subtitle-triangle{
  height: 20px;
  width: 20px;
}

.custom-col-11{

}

@media (max-width: 992px) { 
  .iconWrapper{
    padding: 0 3px;
    margin-top: 8px;
  }
}
</style>