<template>
  <div>
      <div
        v-for="(item, index) in this.data_list" :key="index" :index="index"
        class="w-100 mx-auto"
      >
        <div v-if="index%2 == 0" class="timeline-section-left">
          <div class="row first-line justify-content-center">
            <div class="timeline-div-left-rotate-line relative">
              <div class="rotate-line"></div>
            </div>
            <div 
              class="timeline-div-center relative border-top-white"
              :class="$store.state.wWidth <= 768 ? 'w-70' : 'w-80' "
            >
              <div class="timeline-z pos-c z-0">
                <TimelineItem
                  v-if="data_list[index].type == 'display'"
                  :data_item="data_list[index]"
                  :sidePopup="index%2 == 0 ? 'timeline-left-item' : 'timeline-right-item'"
                />
                <TimelineItemInput
                  v-if="data_list[index].type == 'input'"
                  :data_item="data_list[index]"
                  :sidePopup="index%2 == 0 ? 'timeline-left-item' : 'timeline-right-item'"
                />
              </div>
              
            </div>
            <div class="timeline-div-right border-top-white relative">
              <div v-if="index == 0" class="timeline-start-arrow"></div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="timeline-div-left relative border-left-white">
              <div v-if="index == data_list.length-1" class="small-square light-bg pos-b"></div>
            </div>
            <div
              :class="$store.state.wWidth <= 768 ? 'w-70' : 'w-80' "
            >
            </div>
            <div class="timeline-div-right"></div>
          </div>
        </div>

        <div v-if="index%2 == 1" class="timeline-section-right">
          <div class="row first-line justify-content-center">
            <div class="timeline-div-left border-top-white relative">
            </div>
            <div 
              class="timeline-div-center relative border-top-white"
              :class="$store.state.wWidth <= 768 ? 'w-70' : 'w-80' "
            >
              <div class="pos-c timeline-z z-0">
                <TimelineItem
                  :data_item="data_list[index]"
                  :sidePopup="index%2 == 0 ? 'timeline-left-item' : 'timeline-right-item'"
                />
              </div>
            </div>
            <div class="timeline-div-right relative">
              <div class="rotate-line"></div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="timeline-div-left"></div>
            <div :class="$store.state.wWidth <= 768 ? 'w-70' : 'w-80' ">
            </div>
            <div class="timeline-div-right-underline border-right-white relative">
              <div v-if="index == data_list.length-1"  class="small-square light-bg pos-br"></div>
            </div>
          </div>
        </div>
      </div>
      <H2>{{$store.state.wWidth}}</H2>
  </div>
</template>

<script>
import TimelineItem from '@/components/TimelineItem.vue';
import TimelineItemInput from '@/components/TimelineItemInput.vue';

export default {
  name: 'TimeLine',
  components: {
    TimelineItem,
    TimelineItemInput
  },
  props: {
    data_list: {
      type: Array,
      required: true,
    },
    number_of_element: {
      type: Number,
      default: 8
    },
  }
}
</script>

<style lang="scss">
  .timeline-section-left{
    .first-line{
      height: 41px;
    }
    .timeline-div-left{
      width: 40px;
      height: 60px;
    }
    .timeline-div-left-rotate-line{
      width: 38px;
        .rotate-line{
          height: 35px;
          width: 58px;
          border-top: 1px solid #f4eddb;
          position: absolute;
          transform: rotate(-45deg);
          right: -22px;
          top: 16px;
          z-index: 100;
      }
    }
    .timeline-div-right{
      width: 39px;
    }
  }

  .timeline-section-right{
    .first-line{
      height: 41px;
    }
    .timeline-div-right{
      width: 38px;
        .rotate-line{
          height: 37px;
          width: 57px;
          border-top: 1px solid #f4eddb;
          position: absolute;
          transform: rotate(45deg);
          right: 3px;
          top: 16px;
          z-index: 100;
      }
    }
    .timeline-div-right-underline{
      width: 42px;
      height: 60px;
    }
    .timeline-div-left{
      width: 39px;
    }
  }

  .timeline-section-start-left{
    .timeline-div-left{
      width: 40px;
      height: 60px;
    }
    .timeline-div-left-rotate-line{
      width: 40px;
        .rotate-line{
          height: 35px;
          width: 58px;
          border-top: 1px solid #f4eddb;
          position: absolute;
          transform: rotate(-45deg);
          right: -22px;
          top: 16px;
          z-index: 100;
      }
    }
    .timeline-div-right{
      width: 39px;
    }
  }

  .timeline-start-arrow{
    background-color: #f4eddb;
    /* clip-path: polygon(100% 0, 100% 100%, 0 100%); */
    clip-path: polygon(100% 50%, 0 0, 0 100%);
    height: 25px;
    position: absolute;
    transform: translate(50%, -50%);
    top: 0;
    right: 0;
    width: 12px;
  }

  .timeline-z:hover{
    z-index: 120;
  }

  .z-5000{
    z-index: 10;
    .timeline-item-header{
      z-index: 140;
    }
    .timeline-item-content{
      background-color: #f4eddb;
      z-index: 160;
    }
  }
  .z-0{
    z-index: 0;
  }

  @media (max-width: 768px) {
    // .timeline-section-right .timeline-div-right-underline{
    //   width: 385px;
    //   height: 60px;
    // }

    // .timeline-section-left .timeline-div-left {
    //   width: 77px;
    //   height: 60px;
    // }

    // .timeline-section-left .timeline-div-left-rotate-line .rotate-line {
    //   height: 35px;
    //   width: 56px;
    //   border-top: 1px solid #f4eddb;
    //   position: absolute;
    //   transform: rotate(-45deg);
    //   right: -21px;
    //   top: 15px;
    //   z-index: 100;
    // }
  }
</style>