import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    wWidth: 0,
    wWidthState: ''
  },
  mutations: {
    REFRESH_W_WIDTH(state) {
      window.addEventListener("resize", () => {
        state.wWidth = window.innerWidth;

        if(state.wWidth >= 1400) state.wWidthState = 'xxl';
        else if(state.wWidth >= 1200) state.wWidthState = 'xl';
        else if(state.wWidth >= 992) state.wWidthState = 'lg';
        else if(state.wWidth >= 768) state.wWidthState = 'md';
        else if(state.wWidth >= 576) state.wWidthState = 'sm';
        else state.wWidthState = 'xs';
      })
    }
  },
  actions: {
    updateWindowWidth({ commit }) {
      commit('REFRESH_W_WIDTH')
    }
  }
});