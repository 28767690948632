import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    wWidth: 0
  },
  mutations: {
    REFRESH_W_WIDTH(state) {
      state.wWidth = window.addEventListener("resize", this.onResize);
  }
  },
  actions: {
    updateWindowWidth({ commit }) {
      commit('REFRESH_W_WIDTH')
    }
  }
})