<template>
  <div id="app" class="overflow-hidden">
    <!-- <Loader/> -->
    <MainNav></MainNav>
    <router-view/>
    <footerItem/>
  </div>
</template>

<script>
//import Loader from '@/components/Loader.vue'
import MainNav from '@/components/MainNav.vue'
import FooterItem from './components/FooterItem.vue'

export default {

  components: {
    //Loader,
    MainNav,
    FooterItem
  },
    data() {
    return {
    }
  },
  mounted() {
    this.$store.state.wWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      this.$store.state.wWidth = window.innerWidth;
    })
  }
}
</script>


<style lang="scss">
@font-face {
  font-family: "Reactor";
  src: local("Reactor"),
   url(./assets/fonts/Reactor-Sans.ttf) format("truetype");
}

@font-face {
  font-family: "Maven";
  src: local("Maven"),
   url(./assets/fonts/MavenPro-VariableFont_wght.ttf) format("truetype");
}

#app {
  font-family: 'Maven', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #212529;
}

body{
  font-family: "Maven";
}

h1, h2, h3, h4{
  font-family: "Reactor";
}

h5, h6{
  font-family: "Maven";
}

h4{
  font-size: 11px;
  letter-spacing: 1px;
}

.arrow-top{
  clip-path: polygon(50% 0, 0% 100%, 100% 100%);
}

.bg-primary{
  background-color: #212529;
}

.bg-secondary{
  background-color: #736e5b!important;
}

.black-dot{
  background-color: #212529;
  border-radius: 50px;
  height: 10px;
  width: 10px;
}

.btn-check:focus+.btn, .btn:focus {
    outline: 0;
    box-shadow: unset;
}

.btn-custom-primary {
  color: #f4eddb;
  background-color: #212529;
  border: unset;
  border-radius: 0;
  transition: all, 0.2s;
}

.btn-custom-primary:hover {
  color: #f4eddb;
  transition: all, 0.2s;
}

.btn-custom-secondary {
  color: #f4eddb;
  background-color: #736e5b;
  border: unset;
  border-radius: 0;
  transition: all, 0.2s;
}
.btn-custom-secondary:hover {
  color: #f4eddb;
  transition: all, 0.2s;
}

.bold{
  font-weight: bold;
}

.border-bottom-black{
  border-bottom: 1px solid #212529;
}

.border-left-black{
  border-left: 1px solid #212529;
}

.border-top-black{
  border-top: 1px solid #212529;
}

.border-right-black{
  border-right: 1px solid #212529;
}

.border-bottom-white{
  border-bottom: 1px solid #f4eddb;
}

.border-left-white{
  border-left: 1px solid #f4eddb;
}

.border-top-white{
  border-top: 1px solid #f4eddb;
}

.border-right-white{
  border-right: 1px solid #f4eddb;
}

.dark-bg{
  background-color: #212529;
}

.font-s-8{
  font-size: 8px;
}

.font-s-10{
  font-size: 10px;
}

.font-s-12{
  font-size: 12px;
}

.font-s-14{
  font-size: 14px;
}

.font-s-16{
  font-size: 16px;
}

.font-s-18{
  font-size: 18px;
}

.font-s-24{
  font-size: 24px;
}

.left-0{
  left: 0;
}

.list-subtitle{
  color: #f4eddb;
  font-family: 'Reactor';
  font-size: 7px;
  letter-spacing: 1px;
  height: 20px;
  left: 19px;
  line-height: 20px;
  padding: 0 0 0 3px;
  position: absolute;
  width: 80%;
}

.list-subtitle-triangle{
  height: 20px;
  width: 20px;
}

.light-bg{
  background-color: #f4eddb;
}

.link-white a{
  color: #F4EDDB;
}

.maven{
  font-family: maven;
}

.main-bg-pattern{
  position: relative;
  background-color: #F4EDDB ;
  background-size: 12px 12px ;
  background-image: url('./assets/cross-bg5.png');
}

.pos-b{
  bottom: 0;
  left: 0;
  position: absolute;
  transform: translate(-55%, 50%);
}

.pos-bl{
  bottom: 0;
  left: 0;
  position: absolute;
  transform: translate(-100%, 50%);
}

.pos-br{
  bottom: 0;
  position: absolute;
  right: 0;
  transform: translate(50%, 50%);
}

.pos-c{
  top: 0;
  position: absolute;
  right: 50%;
  transform: translate(50%, 0%);
}

.pos-ct{
  top: 0;
  position: absolute;
  right: 50%;
  transform: translate(50%, -50%);
}

.pos-bm{
  bottom: 0;
  position: absolute;
  right: 0;
  transform: translate(50%, 100%);
}

.pos-tl{
  left: 0;
  position: absolute;
  top: 0;
  transform: translate(50%, -50%);
}

.pos-tr{
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
}

.reactor{
  font-family: reactor;
}

.right-0{
  right: 0;
}

.screen-effect:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.05) 50%);
  z-index: 5000;
  background-size: 100% 4px, 0px 100%;
  -webkit-backdrop-filter: blur(0.4px);
  backdrop-filter: blur(0.4px);
  pointer-events: none;
  -webkit-animation: test 0.35s infinite;
  animation: test 0.15s infinite;
}

.small-triangle-dark-tr{
  height: 10px;
  width: 10px;

}
.small-square{
  height: 10px;
  width: 10px;
}

.small-square-dark-br{
  height: 10px;
  width: 10px;
  background-color: #212529;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(50%, 50%);
}

.triangle-bl{
  clip-path: polygon(0 0, 100% 100%, 0 100%);
}

.triangle-br{
  clip-path: polygon(100% 0, 100% 100%, 0 100%);
}

.triangle-tl{
  clip-path: polygon(0 0, 100% 0, 0 100%);
}

.triangle-tr{
  clip-path: polygon(0 0, 100% 0, 100% 100%);
}

.triangle-pos-tl{
  left: 0;
  position: absolute;
  top: 0;
  transform: translate(-96%, 0%);
}

.triangle-pos-test{
  right: 0;
  position: absolute;
  top: 0;
  transform: translate(97%, 0%);
  z-index: 100;
}

.triangle-pos-tr{
  right: 0;
  position: absolute;
  top: 0;
  transform: translate(-1%, -100%);
}

.triangle-pos-br{
  right: 0;
  position: absolute;
  bottom: 0;
  transform: translate(98%, 0%);
}

.triangle-pos-bl{
  left: 0;
  position: absolute;
  bottom: 0;
  transform: translate(-1.5%, 99%);
}

.medium-square{
  height: 20px;
  width: 20px;
}

.relative{
  position: relative;
}

.white-dot{
  background-color: #f4eddb;
  border-radius: 50px;
  height: 10px;
  width: 10px;
}

.w-70{
  width: 70%;
}

.w-78{
  width: 78%;
}

.w-79{
  width: 79%;
}

.w-80{
  width: 80%;
}

p{
  font-family: maven;
}

.z-1{
  z-index: 1;
}

@media (max-width: 768px) { 
    .section-content{
      max-width: 90%;
    }
}

</style>
