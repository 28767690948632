<template>
<div class="loader screen-effect" :style="{ display: displayLoader, top:topLoader}">
  <div class="container">
    <p class="xl-title text-start reactor mb-0">SM-CV03</p>
    <p class="lg-title text-start reactor mb-5">Chargement de l'interface {{ dot }}</p>
  </div>
  <div class="container loader-container">
    <div class="square-rotate-tl-medium"></div>
    <div class="square-tr"></div>
    <div class="square-bl"></div>
    <div class="row main-row-loader h-100">
      <div class="line-wrapper">
        <div class="line mr-auto">
          <div class="square-rotate-tl-small"></div>
          <div id="screen" class="screen">
            <div id="fakeSnippet"></div>
          </div>
        </div>
      </div>
      <div class="logo-loader-wrapper mx-flex">
        <LogoLargeCyb class="logo-loader"/>
      </div>
      <div class="alert-display-loader row">
        <div class="rotate-text-wrapper">
          <p class="title rotate-text">Curiculum-vitae</p>
        </div>
        <div class="alert-diplay-loader-data">
          <div class="alert-diplay-loader-graph border mt-4 px-2">
            <div class="row m-0 p-1">
              <p class="col-2 p-0 m-0">JS-587</p>
              <div class="col-10">
                <div class="graph-bar graph-bar-1"></div>
              </div>
            </div>
            <div class="row m-0 p-1">
              <p class="col-2 p-0 m-0">AD0B3</p>
              <div class="col-10">
                <div class="graph-bar graph-bar-2"></div>
              </div>
            </div>
            <div class="row m-0 p-1">
              <p class="col-2 p-0 m-0">P?P</p>
              <div class="col-10">
                <div class="graph-bar graph-bar-3"></div>
              </div>
            </div>
            <div class="row m-0 p-1">
              <p class="col-2 p-0 m-0">SQL</p>
              <div class="col-10">
                <div class="graph-bar graph-bar-4"></div>
              </div>
            </div>
            <div class="row m-0 p-1">
              <p class="col-2 p-0 m-0">SEO</p>
              <div class="col-10">
                <div class="graph-bar graph-bar-5"></div>
              </div>
            </div>
        </div>
        <div class="alert-diplay-loader-text">
          <p class="title">Dossier concernant : <strong>Slimen Metatidj</strong></p>
          <p class="subtitle">Document en lecture seule.</p>
          <p class="subtitle">Pour une demande d'élévation contactez un administrateur.</p>
        </div>
        </div>
        

      </div>
      <div class="square-bottom"></div>
      <div class="square-top"></div>
    </div>
  </div>
  <div class="container bg-dark py-5">
    <div class="row">
      <div class="text-center">
        <router-link to="/home">
          <CyberButton
            v-if="showButton == true"
            class="mx-auto"
            :text="'Continuer'"
            @click.native="unShow()"
          />
        </router-link>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import CyberButton from '@/components/ui/button/CyberButton.vue';
import LogoLargeCyb from '@/components/LogoLarge.vue';

export default {
  name: 'MainNav',
  components: {
    CyberButton,
    LogoLargeCyb
  },
  props: {
    msg: String
  },
  data() {
    return {
      displayLoader: 'inherit',
      fakeTextArray: [
        "public void Should_Fake_Single_Instance_of_String()é",
        "{é",
        "    var fake = new Fake<string>();é",

        "    var stringInstance = fake.Generate();é",

        "    Assert.IsNotNullOrEmpty(stringInstance);é",
        "}é",
        "==================================é",
        "public void Should_Fake_Single_Instance_of_String()é",
        "{é",
        "    var fake = new Fake<string>();é",

        "    var stringInstance = fake.Generate();é",

        "    Assert.IsNotNullOrEmpty(stringInstance);é",
        "}é",
        "!! Process ready !!é",

      ],
      dot: '...',
      topLoader: 0,
      cpt: 0,
      showButton: true
    }
  },

  created(){
    if(this.cpt == 0){
      setTimeout(this.generateText, 2000);
      this.ctp = 1;
    }
  },
  mounted(){

  },
  methods: {
    unShow(){
      this.topLoader= '-100%';
      setTimeout(() => {
        this.displayNoneLoader();
      }, 300);
    },
    displayNoneLoader(){
      this.displayLoader = 'none';
    },
    createRow(sentence, rowIndex) {
      return new Promise(resolve => {
        let fakeSnippet = document.getElementById("fakeSnippet");
        let newP = document.createElement("p");
        newP.setAttribute('id', 'line' + rowIndex);
        newP.style.fontSize = '10px'
        fakeSnippet.appendChild(newP);
        let i = 0;
        for (; i < sentence.length; i++) {
          (function(i){
            setTimeout(function(){
              if(sentence[i] == 'é'){
                resolve(i);
              }else{
                newP.innerHTML += sentence[i];
              }
            }, i * 10);
          }(i));
        }
      });
    },

    async generateText() {
      for(let i = 0; i < this.fakeTextArray.length; i++){
        await this.createRow(this.fakeTextArray[i], i);
        if(i == this.fakeTextArray.length-1){
          this.showButton = true;
        }
      }
    },
    getRandom(max) {
      return Math.floor(Math.random() * max);
    },
    getRandomArbitrary(min, max) {
      console.log(Math.random() * (max - min) + min);
      return Math.random() * (max - min) + min;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .alert-display-loader{
    height: 100%;
    position: relative;
    text-align: left;
    width: 38%;

    p{
      color: #f4eddb;
    }
    
    .alert-diplay-loader-data{
      width: 99%;
    }

    .alert-diplay-loader-graph{
      p{
        line-height: 21px;
      }
    }

    .alert-diplay-loader-text {
      margin-top: 15px;
      .title{
        font-family: 'Reactor';
        font-size: 12px!important;
        letter-spacing: 2px;
        margin-bottom: 57px;
        strong{
          display: block;
        }
      }
      .subtitle{
        font-size: 10px;
        margin: 0;
      }
    }

    .title{
      font-family: 'Reactor';
      font-size: 20px;
    }
    
    .rotate-text{
      height: 100%;
      left: 0px;
      position: absolute;
      transform: rotate(-90deg);
    }

    .rotate-text-wrapper{
      padding: 0;
      width: 1px;
    }
  }

  .fakeText{
    font-size: 10px!important;
    margin: 0;
    width: 93%;
  }

  .gradient-text {
    background-clip: text;
    background-image: linear-gradient(60deg, #E21143, #FFB03A);
    color: transparent;
  }

  .graph-bar{
    background-color: #f4eddb;
    height: 12px;
    margin: 5px 0;
  }

  .graph-bar-1{
    animation: bar1 3s infinite;
    background-color: #f4eddb;
    height: 12px;
    margin: 5px 0;
    width: 70%;
  }
  
  .graph-bar-2{
    animation: bar2 1.5s infinite;
    background-color: #f4eddb;
    height: 12px;
    margin: 5px 0;
    width: 80%;
  }

  .graph-bar-3{
    animation: bar3 6s infinite;
    background-color: #f4eddb;
    height: 12px;
    margin: 5px 0;
    width: 75%;
  }

  .graph-bar-4{
    animation: bar5 reverse 2s infinite;
    background-color: #f4eddb;
    height: 12px;
    margin: 5px 0;
    width: 40%;
  }

  .graph-bar-5{
    animation: bar5 3s infinite;
    background-color: #f4eddb;
    height: 12px;
    margin: 5px 0;
    width: 40%;
  }

  .hide-text{
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4500175070028011) 75%, rgba(0,0,0,0.5368522408963585) 80%, rgba(0,0,0,1) 95%, rgba(0,0,0,1) 100%);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10002;
  }

  .lg-title{
    color: #f4eddb;
    font-size: 28px;
  }

  .loader{
    background-color: #212529;
    height: 100%;
    min-height: 800px;
    padding-top: 5%;
    position: absolute;
    top: 00%;
    transition: all 0.6s;
    width: 100%;
    z-index: 9999;
  }

  .loader-container{
    border: 1px solid #f4eddb;
    height: 340px;
    position: relative;
  }

  .line-wrapper{
    height: 100%;
    position: relative;
    width: 30%;
    img{
      height: 100%;
    }
  }

  .line{
    animation: line 2s 1 ;
    background-size: cover;
    background-repeat: no-repeat ;
    height: 80%;
    margin-right: auto;
    padding: 10px 3px 0 3px;
    position: absolute;
    text-align: left;
    top: 50%; left: 50%;
    transform: translate(-60%, -50%);
    width: 60%;
    .screen{
      background: rgb(115,112,106);
      background: linear-gradient(0deg, rgba(115, 112, 106, 0.622) 0%, rgba(255,255,255,0) 100%);
      border: 1px solid #f4eddb;
      height: 100%;
      overflow: hidden;
      padding: 10px 10px 10px 10px;
      position: relative;
      width: 101%;
      #fakeSnippet{
        bottom: 0;
        color: #f4eddb;
        padding-right: 5px;
        text-shadow: 0px 0px 12px white;
        position: absolute;
        width: 95%;
      }

    }
    .textdisplay{
      bottom: 0;
      height: auto;
      overflow: hidden;
      position: absolute;
    }
  }

  .logo-loader{
    left: 45%;
    height: fit-content;
    position: absolute;
    top: 50%; 
    transform: translate(-50%, -50%); 
    width: 260px;
  }

  .logo-loader-wrapper{
    height: 100%;
    width: 32%;
  }

  .xl-title{
    color: #f4eddb;
    font-size: 70px;
  }

  .relative{
    position: relative;
  }

  .screen-warpper{
    height: 300px;
  }

  .square-rotate-tl-medium{
    background-color: #212529;
    border-bottom: 1px solid #f4eddb;
    height: 70px;
    left: -35px;
    position: absolute;
    transform: rotate(-45deg);
    top: -35px;
    width: 70px;
    z-index: 1;
  }

  .square-rotate-tl-small{
    background-color: #212529;
    border-bottom: 1px solid #f4eddb;
    height: 40px;
    left: -16px;
    position: absolute;
    top: -9px;
    transform: rotate(-45deg);
    width: 40px;
    z-index: 1;
  }
  
  .square-top{
    animation: square 2s 1 ;
    background-color: #212529;;
    border-right: 1px solid #f4eddb;
    border-top: 1px solid #f4eddb;
    height: 70px;
    top: 100%; left: 30%;
    transform: translate(-50%, -50%) rotate(-45deg); 
    position: absolute;
    width: 70px;
    z-index: 10001;
  }

  .square-tr{
    border-right: 1px solid #f4eddb;
    border-top: 1px solid #f4eddb;
    height: 25px;
    top: -25px;
    position: absolute;
    right: -25px;
    width: 25px;
  }

  .square-bl{
    border-left: 1px solid #f4eddb;
    border-bottom: 1px solid #f4eddb;
    bottom: -25px;
    left: -25px;
    height: 25px;
    position: absolute;
    width: 25px;
  }

  .square-bottom{
    animation: square 2s 1 ;
    background-color: #212529;;
    border-bottom: 1px solid #f4eddb;
    border-left: 1px solid #f4eddb;
    height: 70px;
    position: absolute;
    top: 0%; left: 30%;
    transform: translate(-50%, -50%) rotate(-45deg); 
    width: 70px;
    z-index: 10001;
  }

  .text {
    height: auto;
    overflow: hidden;
  }

  @media (max-width: 991px) { 
    .line{
      width: 68%;
    }

    .logo-loader{
      height: fit-content;
      left: 45%;
      top: 50%; 
      transform: translate(-50%, -50%); 
      position: absolute;
      width: 140px;
    }
    
    .alert-display-loader{
      padding: 0;
      .title{
        font-size: 17px;
        left: 20px;
      }
      .alert-diplay-loader-graph{
        p{
          line-height: 21px;
          font-size: 11px;
        }
      }
      .alert-diplay-loader-text{
        .title{
          margin-bottom: 25px;
        }
      }
    }
  }

  @media (max-width: 768px) { 
    .alert-display-loader{
      margin-left: auto;
      width: 100%;
      .title{
        font-size: 13px;
        left: 35px;
        top: -49px;
      }
      .alert-diplay-loader-graph{
        margin-top: 0!important;
      }
      .alert-diplay-loader-text {
        margin-top: 8px;
        .title{
          font-family: 'Reactor';
          font-size: 10px!important;
          letter-spacing: 2px;
          margin-bottom: 8px;
          strong{
            display: inline-block;
          }
        }
        .subtitle{
          font-size: 10px;
          margin: 0;
        }
      }
    }

    .lg-title{
      font-size: 17px;
      margin-bottom: 35px!important;
    }

    .line-wrapper{
      width: 50%;
    }

    .loader{
      padding: 0;
    }

    .loader-container{
      height: 540px;
    }

    .logo-loader-wrapper{
      position: relative;
      width: 50%;
    }

    .logo-loader{
      left: 45%;
      position: absolute;
      top: 50%; 
      transform: translate(-50%, -50%); 
      height: fit-content;
      width: 180px;
    }

    .main-row-loader{
      max-height: 280px;
    }
    
    .square-bottom{
      height: 40px;
      left: 50%;
      width: 40px;
    }
    .square-top{
      height: 40px;
      left: 50%;
      width: 40px;
    }

    .xl-title{
      font-size: 55px;
    }
  }

  @media (max-width: 600px) { 
    .loader-container{
      width: 90%;
    }
  }

  @media (max-width: 470px) { 
    .loader-container{
      width: 80%;
    }
    .logo-loader{
      width: 80%;
    }
    .screen{
      width: 125%!important;
    }
  }

  @keyframes bar1 {
    0% {
      background-color: #f4eddb;
      height: 12px;
      width: 70%;
    }
    25% {
      background-color: #f4eddb;
      height: 12px;
      width: 75%;
    }
    50% {
      background-color: #f4eddb;
      height: 12px;
      width: 80%;
    }
    75% {
      background-color: #f4eddb;
      height: 12px;
      width: 60%;
    }
    100% {
      background-color: #f4eddb;
      height: 12px;
      width: 70%;
    }
  }

  @keyframes bar2 {
    0% {
      background-color: #f4eddb;
      height: 12px;
      width: 80%;
    }
    25% {
      background-color: #f4eddb;
      height: 12px;
      width: 85%;
    }
    50% {
      background-color: #f4eddb;
      height: 12px;
      width: 60%;
    }
    75% {
      background-color: #f4eddb;
      height: 12px;
      width: 100%;
    }
    100% {
      background-color: #f4eddb;
      height: 12px;
      width: 80%;
    }
  }

  @keyframes bar3 {
    0% {
      background-color: #f4eddb;
      height: 12px;
      width: 75%;
    }
    10% {
      background-color: #f4eddb;
      height: 12px;
      width: 80%;
    }
    20% {
      background-color: #f4eddb;
      height: 12px;
      width: 85%;
    }
    30% {
      background-color: #f4eddb;
      height: 12px;
      width: 90%;
    }
    40% {
      background-color: #f4eddb;
      height: 12px;
      width: 85%;
    }
    50% {
      background-color: #f4eddb;
      height: 12px;
      width: 80%;
    }
    60% {
      background-color: #f4eddb;
      height: 12px;
      width: 75%;
    }
    70% {
      background-color: #f4eddb;
      height: 12px;
      width: 70%;
    }
    80% {
      background-color: #f4eddb;
      height: 12px;
      width: 65%;
    }
    90% {
      background-color: #f4eddb;
      height: 12px;
      width: 70%;
    }
    100% {
      background-color: #f4eddb;
      height: 12px;
      width: 75%;
    }
  }
  @keyframes bar5 {
    0% {
      background-color: #f4eddb;
      height: 12px;
      width: 50%;
    }
    25% {
      background-color: #f4eddb;
      height: 12px;
      width: 55%;
    }
    50% {
      background-color: #f4eddb;
      height: 12px;
      width: 65%;
    }
    75% {
      background-color: #f4eddb;
      height: 12px;
      width: 60%;
    }
    100% {
      background-color: #f4eddb;
      height: 12px;
      width: 50%;
    }
  }

  @keyframes line {
    0% {
      height: 80%;
      width: 0;
    }
    100% {
      height: 80%;
      width: 60%;
    }
  }

  @keyframes square {
    0% {
      height: 0;
      width: 0;
    }
    20% {
      height: 50px;
      width: 50px;
    }
    100% {
    }
  }
</style>
