<template>
    <div class="logo-wrapper">
        <img class="logo" src="../assets/logo-cyb.png" alt="">
        <img class="glitched-logo" src="../assets/logo-cyb.png" alt="">
    </div>
</template>

<script>
export default {
    name: 'LogoCyb',
}
</script>

<style lang="scss">
.logo-wrapper{
    position: relative;
    width: 100%;
}

.logo-wrapper img{
  width: 100%;
}

.logo-wrapper:hover{
      img{
        background-image: url('../assets/logo-cyb-corner-only.png');
        background-size: cover;
      }
}

.logo-wrapper:hover > .glitched-logo{
      animation: glitch 1s infinite;
      display: inherit;
      width: 100%;
      height: auto;
    } 

    .glitched-logo{
      background-color: #212529;
      clip-path: polygon(0 0%, 100% 0%, 100% 15%, 0 15%);
      display: none;
      left: 0;
      position: absolute;
      top: 0;
      --shimmy-distance: 3;
      --clip-one: polygon(0 0%, 100% 0%, 100% 15%, 0 15%);
      --clip-x: polygon(polygon(0 0%, 100% 0%, 100% 15%, 0 15%));
      --clip-two: polygon(0 20%, 100% 20%, 100% 43%, 0 43%);
      --clip-three: polygon(0 43%, 100% 43%, 100% 55%, 0 55%);
      --clip-four: polygon(0 58%, 100% 58%, 100% 80%, 0 80%);
      --clip-five: polygon(0 80%, 100% 80%, 100% 100%, 0 100%);
    }
@keyframes glitch {
    0% {
      clip-path: var(--clip-one);
    }
    10%, 26% {
      clip-path: var(--clip-one);
      transform: translate(calc(var(--shimmy-distance) * -4%), 0);
    }
    18%, 34% {
      clip-path: var(--clip-two);
      transform: translate(calc(var(--shimmy-distance) * -2%), 0);
    }
    30% {
      clip-path: var(--clip-two);
      transform: translate(calc(var(--shimmy-distance) * 2%), 0);
    }
    40% {
      clip-path: var(--clip-two);
      transform: translate(0, 0);
    }
    45% {
      clip-path: var(--clip-three);
      transform: translate(calc(var(--shimmy-distance) * 2%), 0);
    }
    50% {
      clip-path: var(--clip-three);
      transform: translate(0, 0);
    }
    48%, 60% {
      clip-path: var(--clip-four);
      transform: translate(calc(var(--shimmy-distance) * 2%), 0);
    }
    70%, 80% {
      clip-path: var(--clip-two);
      transform: translate(calc(var(--shimmy-distance) * 2%), 0);
    }
    80% {
      clip-path: var(--clip-five);
      transform: translate(calc(var(--shimmy-distance) * 2%), 0);
    }
    86%, 92% {
      clip-path: var(--clip-three);
      transform: translate(calc(var(--shimmy-distance) * -4%), 0);
    }
    92% {
      clip-path: var(--clip-five);
      transform: translate(calc(var(--shimmy-distance) * -2%), 0);
    }
    31%, 40%, 100% {
      clip-path: var(--clip-four);
    }
  }
</style>