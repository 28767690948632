<template>
    <div class="logo-large-wrapper">
        <img class="logo" src="../assets/logo-cyb.png" alt="">
        <img class="glitched-logo-large" src="../assets/logo-cyb.png" alt="">
        <!-- <img class="cl1" src="../assets/logo-cyb.png" alt="">
        <img class="cl2" src="../assets/logo-cyb.png" alt="">
        <img class="cl3" src="../assets/logo-cyb.png" alt="">
        <img class="cl4" src="../assets/logo-cyb.png" alt="">
        <img class="cl5" src="../assets/logo-cyb.png" alt="">
        <img class="cl6" src="../assets/logo-cyb.png" alt="">
        <img class="cl7" src="../assets/logo-cyb.png" alt="">
        <img class="cl8" src="../assets/logo-cyb.png" alt="">
        <img class="cl9" src="../assets/logo-cyb.png" alt="">
        <img class="cl10" src="../assets/logo-cyb.png" alt=""> -->

    </div>
</template>

<script>
export default {
    name: 'LogoLarge',
  data() {
    return {
      logoHtml: document.getElementsByClassName('glitched-logo-large')
    }
  },
  mounted(){
    
    
    setInterval(() => {
      let logo = document.getElementsByClassName('glitched-logo-large');
       logo[0].style.animation = 'glitchLarge 1s infinite';
    }, 1000);
    setInterval(() => {
      let logo = document.getElementsByClassName('glitched-logo-large');
       logo[0].style.animation = 'unset';
    }, 8000);
  },
  methods:{

  }
}
</script>

<style lang="scss">
.cl1{
  position: relative;
    height: 100%;
  background-color: red;
  clip-path: polygon(0 0%, 100% 0%, 100% 5%, 0 5%);
  
}

.cl2{
  position: relative;
    height: 100%;
  background-color: red;
  clip-path: polygon(0 5%, 100% 5%, 100% 14%, 0 14%);
}
.cl3{
 position: relative;
    height: 100%;
  background-color: red;
  clip-path: polygon(0 14%, 100% 14%, 100% 20%, 0 20%);
}
.cl4{
 position: relative;
    height: 100%;
  background-color: red;
  clip-path: polygon(0 20%, 50% 20%, 50% 25%, 0 25%);
}
.cl5{
 position: relative;
    height: 100%;
  background-color: red;
  clip-path: polygon(0 25%, 100% 25%, 100% 38%, 0 38%);
}
.cl6{
 position: relative;
    height: 100%;
  background-color: red;
  clip-path: polygon(50% 38%, 100% 38%, 100% 45%, 50% 45%);
}
.cl7{
 position: relative;
    height: 100%;
  background-color: red;
  clip-path: polygon(0 45%, 100% 45%, 100% 60%, 0 60%);
}

.cl8{
 position: relative;
    height: 100%;
  background-color: red;
  clip-path: polygon(0 60%, 100% 60%, 100% 70%, 0 70%);
}
.cl9{
 position: relative;
    height: 100%;
  background-color: red;
  clip-path: polygon(0 70%, 100% 70%, 100% 90%, 0 90%);
}

.cl10{
 position: relative;
    height: 100%;
  background-color: red;
  clip-path: polygon(0 85%, 100% 85%, 100% 95%, 0 95%);
}

.logo{
  margin-right: auto;
  display: block;
  max-width: 100%;
}

.logo-large-wrapper{
  position: relative;
  height: 100%;
}

.logo-large-wrapper:hover{
  img{
    background-image: url('../assets/logo-cyb-corner-only.png');
    background-size: cover;
  }
}

.glitched-logo-large{
  background-color: #212529;
  //background-color: #3990e7;
  clip-path: polygon(0 0%, 100% 0%, 100% 15%, 0 15%);
  display: inherit;
  left: 0;
  position: absolute;
  top: 0;
  --shimmy-distance: 3;
  --clip-one: polygon(0 0%, 100% 0%, 100% 5%, 0 5%);
  --clip-two: polygon(0 5%, 100% 5%, 100% 14%, 0 14%);
  --clip-three: polygon(0 14%, 100% 14%, 100% 20%, 0 20%);
  --clip-four: polygon(0 20%, 50% 20%, 50% 25%, 0 25%);
  --clip-five: polygon(0 25%, 100% 25%, 100% 38%, 0 38%);
  --clip-six: polygon(0 14%, 100% 14%, 100% 20%, 0 20%);
  --clip-seven: polygon(0 35%, 100% 35%, 100% 60%, 0 60%);
  --clip-eight: polygon(0 60%, 100% 60%, 100% 70%, 0 70%);
  --clip-nine: polygon(0 70%, 100% 70%, 100% 90%, 0 90%);
  --clip-ten: polygon(0 90%, 100% 90%, 100% 100%, 0 100%);

  width: 100%;
}

@keyframes glitchLarge {
    0% {
      clip-path: var(--clip-one);
    }
    0%, 15% {
      clip-path: var(--clip-one);
      transform: translate(calc(var(--shimmy-distance) * -0.5%), 0);
    }
    15%, 20% {
      clip-path: var(--clip-two);
      transform: translate(calc(var(--shimmy-distance) * 0.5%), 0);
    }

    25%{
      clip-path: var(--clip-three);
      transform: translate(calc(var(--shimmy-distance) * -3%), 0);
    }
    32%{
      clip-path: var(--clip-four);
      transform: translate(calc(var(--shimmy-distance) * -0.5%), 0);
    }
    38% {
      clip-path: var(--clip-two);
      transform: translate(calc(var(--shimmy-distance) * -0.5%), 0);
    }
    42% {
      clip-path: var(--clip-four);
      transform: translate(calc(var(--shimmy-distance) * -0.5%), 0);
    }
    42%, 48% {
      clip-path: var(--clip-five);
      transform: translate(calc(var(--shimmy-distance) * -0.5%), 0);
    }
    48%, 62% {
      clip-path: var(--clip-six);
      transform: translate(calc(var(--shimmy-distance) * 0.5%), 0);
    }
    62% {
      clip-path: var(--clip-five);
      transform: translate(calc(var(--shimmy-distance) * -0.5%), 0);
    }
    62% {
      clip-path: var(--clip-seven);
      transform: translate(0, 0);
    }
    66% {
      clip-path: var(--clip-six);
      transform: translate(calc(var(--shimmy-distance) * -0.5%), 0);
    }
    66%, 74% {
      clip-path: var(--clip-eight);
      transform: translate(calc(var(--shimmy-distance) * 2%), 0);
    }
    74%, 80% {
      clip-path: var(--clip-four);
      transform: translate(calc(var(--shimmy-distance) * -0.5%), 0);
    }
    80%, 85% {
      clip-path: var(--clip-five);
      transform: translate(calc(var(--shimmy-distance) * -0.5%), 0);
    }

    85%, 90% {
      clip-path: var(--clip-nine);
      transform: translate(calc(var(--shimmy-distance) * -0.5%), 0);
    }
    90%, 100% {
      clip-path: var(--clip-ten);
      transform: translate(calc(var(--shimmy-distance) * -1%), 0);
    }

    95% {
      clip-path: var(--clip-nine);
    }
    
  }
</style>