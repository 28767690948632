<template>
  <div class="w-100 p-0 relative">
    <h4 v-if="title" class="mb-1">{{ title }}</h4>
    <router-link :to="link">
      <div class="picture-btn relative">
      <article class="w-100 screen-effect  p-0 relative">
        <img class="w-100" :src="require('@/' + this.pathToPicture)" alt="Catégorie graphisme">
      </article>
      <article class="w-100 picture-btn-glitch  p-0 relative">
        <img class="w-100" :src="require('@/' + this.pathToPicture)" alt="Catégorie graphisme">
      </article>
    </div>
    <div class="list-subtitle relative dark-bg">
      <div class="triangle-tr triangle-pos-tl list-subtitle-triangle dark-bg"></div>
      <div class="triangle-tl triangle-pos-br list-subtitle-triangle dark-bg"></div>
      {{ subtitle }}
    </div>
    </router-link>
    
  </div>
</template>

<script>

export default {
  name: 'PictureBt',
  props:{
    pathToPicture: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: false,
      default: '/'
    },
    title: {
      type: String
    },
    subtitle: {
      type: String,
      default: "Files assets 32PQS"
    },
  },
  data(){
    return {
      
    }
  },
  mounted(){

  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>

.list-subtitle{
  color: #f4eddb;
  font-family: 'Reactor';
  font-size: 7px;
  letter-spacing: 1px;
  height: 20px;
  left: 19px;
  line-height: 20px;
  padding: 0 0 0 3px;
  position: absolute;
  width: 80%;
}

.list-subtitle-triangle{
  height: 20px;
  width: 20px;
}

.picture-btn:hover > .picture-btn-glitch{
  display: inherit;
}

.picture-btn{
  border: 1px solid #212529;
  border-top: 6px solid black;
  margin-top: -1px;
  box-shadow: inset 0px 0px 5px black;
}

.picture-btn-glitch{
  animation: glitchPicture 0.4s alternate 2;
  left: 0;
  top: 0px;
  display: none;
  padding: 8px 6px;
  position: absolute;
  width: 100%;
  height: 100%;
  --shimmy-distance: 0.6;
  --clip-one: polygon(0 0%, 100% 0%, 100% 10%, 0 10%);
  --clip-two: polygon(0 5%, 100% 5%, 100% 24%, 0 24%);
  --clip-three: polygon(0 24%, 100% 24%, 100% 45%, 0 45%);
  --clip-four: polygon(0 45%, 50% 45%, 50% 50%, 0 50%);
  --clip-five: polygon(0 50%, 100% 50%, 100% 90%, 0 90%);
  // --clip-six: polygon(0 70%, 100% 70%, 100% 90%, 0 90%);
  --clip-six: polygon(0 90%, 100% 90%, 100% 100%, 0 100%);
}

@keyframes glitchPicture {
    0% {
      clip-path: var(--clip-one);
    }
    0%, 25% {
      clip-path: var(--clip-one);
      transform: translate(calc(var(--shimmy-distance) * 2%), 0);
    }
    25%, 45% {
      clip-path: var(--clip-two);
      transform: translate(calc(var(--shimmy-distance) * -2%), 0);
    }
    45%, 55% {
      clip-path: var(--clip-three);
      transform: translate(calc(var(--shimmy-distance) * 1%), 0);
    }
    55%, 75% {
      clip-path: var(--clip-four);
      transform: translate(calc(var(--shimmy-distance) * -10%), 0);
    }
    78%{
      clip-path: var(--clip-three);
      transform: translate(calc(var(--shimmy-distance) * -0.1%), 0);
    }
    75%, 90% {
      clip-path: var(--clip-five);
      transform: translate(calc(var(--shimmy-distance) * 10%), 0);
    }
    90%, 100% {
      clip-path: var(--clip-six);
      transform: translate(calc(var(--shimmy-distance) * 2%), 0);
    }
  }

@media (max-width: 551px) { 
   
}
</style>