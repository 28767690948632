<template>
  <section id="PortfolioSummary" class="main-bg-pattern">
    <CategoryHeader
      :categoryData="this.categoryData"
    />
    <div class="container pb-5 mt-5">
      <div class="row">
        <div class="col-md-7 relative pe-3 text-start section-content">
          <p 
            v-for="(paragraph, index) in categoryText" :key="index"
            :class="paragraph.class"
          >
            <span
              v-for="(span, index) in paragraph" :key="index"
              :class="span.class"
            >{{ span.text }}</span>
          </p>
          <div class="small-square dark-bg pos-tr"></div>
          <div class="medium-square dark-bg pos-bl"></div>
        </div>
      </div>
    </div>
  </section>    
</template>

<script>
import CategoryHeader from '@/components/ui/CategoryHeader.vue';
export default {
  title: "Slimen Metatidj",
  name: 'SkillView',
  components: {
    CategoryHeader,
  },
  data(){
    return {
      PortfolioSummaryTitle: "Développement",
      categoryText: [],
      categoryData: [],
      graphicsData: [
        'assets/image/portfolio/graph-miniature.jpg',
        'Une de mes illustrations'
      ],
      graphicsText: {
        sentence: [
          {
            class: 'section-header-first-letter',
            text: 'C'
          },
          {
            class: '',
            text: 'omment ne pas se perdre dans les oeuvres graphiques qui nous entourent ?'
          },
          {
            class: 'd-block',
            text: ''
          },
          {
            class: '',
            text: "Tantôt réalistes ou complètement fantasmés, le dessin, l'illustration et même la photographie sont de ces"
          },
          {
            class: 'd-inline bold',
            text: ' langues universel'
          },
          {
            class: '',
            text: '.'
          },
          {
            class: 'd-block',
            text: ''
          },
          {
            class: '',
            text: 'Fenêtres vers des'
          },
          {
            class: 'bold',
            text: ' "hauts de là" '
          },
          {
            class: '',
            text: 'nous promettant de par leurs observations un regard sur notre monde et transportant bien souvent un message, parfois'
          },
          {
            class: 'bold',
            text: " sans que l'on ne s'en rende compte."
          }
        ]
      },
      develpmentData: [
        'assets/image/portfolio/code-miniature.jpg',
        'Un de mes projets de développement'
      ],
      developmentText: {
          sentence: [
            {
              class: 'section-header-first-letter',
              text: 'A'
            },
            {
              class: '',
              text: "ctivité coeur du métier, le Développement est un vaste domaine dont il est presque impossible d'atteindre le bout."
            },
            {
              class: 'd-block',
              text: ''
            },
            {
              class: '',
              text: "J'ai commencé par la grande porte du web-design avant de découvrir la programmation en elle même."
            },
            {
              class: 'd-inline',
              text: "Ma première formation m'a incqulqué les bases avec l'élaboration de maquette."
            }
          ]
        }
    }
  },
  mounted(){
    this.$route.name == 'graphics' ? this.categoryText = this.graphicsText : this.categoryText = this.developmentText;
    if(this.$route.name == 'graphics'){
      this.categoryText = this.graphicsText;
      this.categoryData = this.graphicsData;
    }else if(this.$route.name == 'development'){
      this.categoryText = this.developmentText;
      this.categoryData = this.develpmentData;
    }
  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>
.section-content{
  border-top: 1px solid black;
  border-left: 1px solid black;
  padding: 10px 0 0 15px;
}

.pos-bl{
  bottom: 0;
  left: 0;
  position: absolute;
  transform: translate(-50%, 50%);
}

// #skillView::after{
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   height: 800px;
//   width: 100%;
//   z-index: 0!important;
//   opacity: 0.10;
//   background-size: 8px 8px ;
//   background-image: url('../assets/cross-bg4.png');
// }

// .custom-col-1{
//   width: ;
// }

.custom-col-11{

}

@media (max-width: 768px) { 
    // .custom-col-11{
    //   width: 91%!important;
    //   padding: 0!important;
    // }
    // .custom-col-1{
    //   width: 45px!important;
    // }
}
@media (max-width: 551px) { 
    // .custom-col-11{
    //   width: 82%!important;
    // }
    // .custom-col-1{
    //   width: 39px!important;
    // }
}
</style>