<template>
  <div class="section-header relative">
    <div class="display-big-title">{{ sectionNumber }}{{ sectionTitle }}</div>
    <div class="container">
      <div class="row">
        <div class="col-1 border-right-black pb-4">
          
        </div>
        <div class="col-11 p-0">
          <div class="col-8 relative border-top-black">
            <div class="small-square dark-bg pos-tr"></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-1 border-right-black"></div>
        <div class="col-11">
            <p 
              v-for="(paragraph, index) in sectionText" :key="index"
              :class="paragraph.class"
            >
              <span
                v-for="(span, index) in paragraph" :key="index"
                :class="span.class"
              >{{ span.text }}</span>
            </p>
        </div>
      </div>

      <!-- <p class="ps-3 col-xl-8"><span class="section-header-first-letter">{{ sectionTextFirstLetter }}</span>{{ sectionTruncateText }}</p> -->

      <div class="row">
        <div class="col-1 relative arrow-border-wrapper relative">
            <div class="display-vertical-text">
              SM-CV03
            </div>
            <div class="display-section">
              <p class="display-section-number">{{ sectionNumber }}</p>
              <div class="display-section-name">
                <p>{{ sectionTitle }}</p>
              </div>
            </div>
          <div class="arrow-border"></div>
        </div>
        <div class="col-11">
        </div>
      </div>
    </div>
  </div>    
</template>

<script>
export default {
  name: 'SectionHeader',
  props:{
    sectionText: {
      type: Object,
      default() {
        return {
          sentence: [
            {
              class: 'section-header-first-letter',
              text: 'Lo'
            },
            {
              class: '',
              text: 'rem ipsum dolor sit amet consectetur adipisicing elit.'
            },
            {
              class: 'bold',
              text: ' Magnam id provident'
            },
            {
              class: '',
              text: ' dolor inventore repudiandae ratione harum nostrum perferendis,'
            },
            {
              class: 'bold d-block',
              text: ' ipsam laboriosam magni '
            },
            {
              class: '',
              text: 'soluta eligendi quam consequuntur vero ullam et! Fuga, reiciendis labore. Error, officia. Consequuntur, sapiente!'
            }
          ]
        }
      }
    },
    sectionTitle: {
      type: String,
      default: 'Magnam id'
    },
    sectionNumber: {
      type: String,
      default: 'xx'
    },
  },
  data(){
    return {
    }
  },
  mounted(){
  },
  methods:{
  }
}
</script>

<style lang="scss" scoped>
.display-vertical-text{
  font-family: "Reactor";
  font-size: 60px;
  left: 24px;
  text-align: end;
  top: 638px;
  position: absolute;
  transform-origin: 0 0;
  transform: rotate(-90deg);
  width: 600px;
}

.section-header{
  z-index: 1;
  p{
    text-align: left;
  }
  .arrow-border-wrapper{
    height: 36px;
  }
  .arrow-border{
    height: 25px;
    width: 25px;
    position: absolute;
    transform: rotate(45deg);
    right: -12px;
    top: 5px;
    border-top: 1px solid #212529;
    border-right: 1px solid #212529;
    z-index: 100;
  }

  .display-big-title{
    position: absolute;
    font-size: 128px;
    font-family: 'Reactor';
    z-index: -10;
    opacity: 0.06;
    width: 200%;
    text-align: start;
  }
  .display-section{
    height: 34px;
    top: 50%;
    right: -11px;
    position: absolute;
    transform: translate(0%, -50%);
    width: 150px;
    
    .display-section-name{
      background-color: #212529;
      clip-path: polygon(88.5% 0, 100% 50%, 88.5% 100%, 0 100%, 0 0);
      height: 100%;
      width: 100%;
      p{
        font-family: 'Reactor';
        font-size: 9px;
        color: #f4eddb;
        line-height: 34px;
        padding-left: 8px;
      }
    }
  }
  .display-section-number{
    height: 34px;
    top: 50%;
    left: -0%;
    position: absolute;
    transform: translate(-100%, -50%);
    font-family: "Reactor";
    font-size: 34px;
    color: #212529;
    line-height: 29px;
    padding-left: 8px;
  }

}

.section-header-first-letter{
  line-height: 42px;
  font-size: 44PX;
}

.custom-col-11{

}

@media (max-width: 1400px) { 
    .display-vertical-text{
      font-size: 60px;
      /* height: 800px; */
      left: 8px;
    }
}

@media (max-width: 1200px) { 
    .display-vertical-text{
      font-size: 60px;
      /* height: 800px; */
      left: -7px;
    }
}

@media (max-width: 992px) { 
    .display-vertical-text{
      font-size: 43px;
      /* height: 800px; */
      left: -3px;
    }
    .display-big-title{
      font-size: 88px;
    }
}

@media (max-width: 768px) { 
    .display-vertical-text{
      font-size: 43px;
      /* height: 800px; */
      left: -18px;
    }
}

@media (max-width: 551px) { 
    .custom-col-11{
      // width: 82%!important;
    }

    .display-vertical-text{
      font-size: 28px;
      /* height: 800px; */
      left: -3px;
    }
}
</style>