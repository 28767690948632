<template>
  <section class="main-bg-pattern">
    <ViewNav :sectionId="'portfolioView'" :sectionPath="'../files/portfolio.slm'" class="mb-5"/>
    <SectionHeader
      :sectionTitle="'Portfolio'"
      :sectionText="portfolioViewText"
      :sectionNumber="'03'"
    />
    <div class="container pb-5">
      <div class="row">
        <div class="col-1 custom-col-1 relative border-right-black">
          <div class="medium-square dark-bg pos-bm"></div>
        </div>
        <div class="col-md-10 custom-col-11 row ps-sm-5 ps-md-4 text-start section-content portfolio-margin justify-content-around">
          <div 
            class="col-md-5 px-5 p-sm-0"
          >
            <PictureBtn
              :pathToPicture="'assets/image/portfolio/graph-miniature.jpg'"
              :link="'/graphisme'"
              :title="'Graphisme'"
              :subtitle="'Graphics evidence'"
            />
          </div>
          <div 
            class="col-md-5 px-5 p-sm-0"
            :class="$store.state.wWidth <= 768 ? 'mt-5' : 'mt-O' "
          >
            <PictureBtn
              :pathToPicture="'assets/image/portfolio/code-miniature.jpg'"
              :link="'/developpement'"
              :title="'Développement'"
              :subtitle="'Development evidence'"
            />
          </div>
        </div>
      </div>
    </div>
  </section>    
</template>

<script>
import ViewNav from '@/components/ViewNav.vue';
import PictureBtn from '@/components/ui/PictureBtn.vue';
import SectionHeader from '@/components/ui/SectionHeader.vue';
export default {
  title: "Slimen Metatidj",
  name: 'SkillView',
  components: {
    ViewNav,
    SectionHeader,
    PictureBtn
  },
  data(){
    return {
      portfolioViewText: {
       sentence: [
          {
            class: 'section-header-first-letter',
            text: 'Q'
          },
          {
            class: '',
            text: 'ue ce soit au travers du'
          },
          {
            class: 'bold',
            text: ' graphisme'
          },
          {
            class: '',
            text: ' ou dans la'
          },
          {
            class: 'bold',
            text: ' conception technique'
          },
          {
            class: '',
            text: ", j'aime mettre ma sensibilité et ma logique en application."
          },
          {
            class: 'd-block',
            text: "L'attrait principal dans le métier de développeur c'est irréfutablement la possibilité de pratiquer des disciplines de prime abord sans liens et qui pourtant, se révèlent complémentaires."
          }
        ]
      },
    }
  },
  mounted(){

  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>
// #skillView::after{
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   height: 800px;
//   width: 100%;
//   z-index: 0!important;
//   opacity: 0.10;
//   background-size: 8px 8px ;
//   background-image: url('../assets/cross-bg4.png');
// }

// .custom-col-1{
//   width: ;
// }

.portfolio-margin{
  margin-bottom: 240px;
}

.custom-col-11{

}

@media (max-width: 768px) { 
    // .custom-col-11{
    //   width: 91%!important;
    //   padding: 0!important;
    // }
    // .custom-col-1{
    //   width: 45px!important;
    // }
}
@media (max-width: 551px) { 
    // .custom-col-11{
    //   width: 82%!important;
    // }
    // .custom-col-1{
    //   width: 39px!important;
    // }
}
</style>